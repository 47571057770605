import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, Signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { take } from 'rxjs';
import { AdvocateCurrencyOptions, SearchBudgetErrorInterface, SearchCampaignGetReponseData } from '../../../models';
import { FormService } from '../../../services/form.service';
import { ButtonComponent } from '../../../shared/button/button.component';
import { DatepickerComponent } from '../../../shared/datepicker/datepicker.component';
import { IconComponent } from '../../../shared/icon/icon.component';
import { InfoIconComponent } from '../../../shared/info-icon/info-icon.component';
import { BackendErrorTranslate } from '../../../shared/pipes/backend-error-i18n.pipe';
import { ErrorHandlingViewModelService } from '../../view-model/error-handling-vm.service';
import { UtilityViewModelService } from '../../view-model/utility-vm.service';

@Component({
    selector: 'advocate-ui-budget-search-page',
    templateUrl: './budget-search-page.component.html',
    styleUrls: ['./budget-search-page.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ButtonComponent,
        DatepickerComponent,
        IconComponent,
        MatRadioModule,
        InfoIconComponent,
        TranslateModule,
        BackendErrorTranslate
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetSearchPageComponent implements OnInit {
    currencySignal: Signal<AdvocateCurrencyOptions | undefined> = toSignal(this.utilityService.getCurrencyEffect());
    form: FormGroup = this.formService.searchBudgetGroup;
    searchBudgetErrorSignal: WritableSignal<SearchBudgetErrorInterface | null> = this.errorService.searchBudgetErrorSignal

    get startDateControl(): FormControl {
        return this.form.get('start_date') as FormControl;
    }

    get endDateControl(): FormControl {
        return this.form.get('end_date') as FormControl;
    }

    constructor(private formService: FormService, private errorService: ErrorHandlingViewModelService, private utilityService: UtilityViewModelService, private activatedRoute: ActivatedRoute, private router: Router) { }

    ngOnInit(): void {
        const id = this.activatedRoute.snapshot.paramMap.get('id');

        if (id && this.router.url.includes('step3')) {
            this.utilityService.getSearchCampaignStepsEffect(+id).pipe(take(1)).subscribe({
                next: (res: SearchCampaignGetReponseData) => {
                    this.form.setValue({
                        start_date: res?.campaign.step3.start_date,
                        end_date: res?.campaign.step3.end_date,
                        daily_budget: res?.campaign.step3.daily_budget,
                        cpc_bid: res?.campaign.step3.cpc_bid || 0
                    });
                },
                error: _ => this.router.navigate(['/home'])
            })
        }
    }
}
