import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, WritableSignal } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription, take } from 'rxjs';
import { CampaignSteps, SearchAdsErrorInterface, SearchCampaignGetReponseData, SearchCreativeItem, SearchStep2Item } from '../../../models';
import { FormService } from '../../../services/form.service';
import { ButtonComponent } from '../../../shared/button/button.component';
import { DisclaimerComponent } from '../../../shared/disclaimer/disclaimer.component';
import { DropDownComponent } from '../../../shared/dropdown/dropdown.component';
import { IconComponent } from '../../../shared/icon/icon.component';
import { BackendErrorTranslate } from '../../../shared/pipes/backend-error-i18n.pipe';
import { AdsTableComponent } from '../../../shared/tables/ads-table/ads-table.component';
import { ErrorHandlingViewModelService } from '../../view-model/error-handling-vm.service';
import { UtilityViewModelService } from '../../view-model/utility-vm.service';

@Component({
    selector: 'advocate-ui-ads-page',
    templateUrl: './ads-page.component.html',
    styleUrls: ['./ads-page.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        ButtonComponent,
        DropDownComponent,
        IconComponent,
        MatTooltipModule,
        DisclaimerComponent,
        FormsModule,
        ReactiveFormsModule,
        AdsTableComponent,
        TranslateModule,
        BackendErrorTranslate
    ],
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdsPageComponent implements OnInit, OnDestroy {
    private subs = new Subscription();
    form = this.formService.adsFormGroup;
    searchHeadlinesFormArray = this.formService.searchHeadlinesFormArray;
    searchDescriptionsFormArray = this.formService.searchDescriptionsFormArray;
    searchAdErrorSignal: WritableSignal<SearchAdsErrorInterface | null> = this.errorService.searchAdErrorSignal;

    constructor(private fb: FormBuilder,
        private formService: FormService,
        private utilityService: UtilityViewModelService,
        private errorService: ErrorHandlingViewModelService,
        private translateService: TranslateService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit(): void {
        const id = this.activatedRoute.snapshot.paramMap.get('id');

        if (id && this.router.url.includes('step2')) {
            this.utilityService.getSearchCampaignStepsEffect(+id).pipe(take(1)).subscribe({
                next: (res: SearchCampaignGetReponseData) => {
                    res.campaign.step2.forEach((creative: SearchStep2Item) => {
                        this.formService.adsFormGroup.patchValue({
                            headline_1: creative.creative.headline_1,
                            headline_2: creative.creative.headline_2,
                            headline_3: creative.creative.headline_3,
                            description_1: creative.creative.description_1,
                            description_2: creative.creative.description_2,
                            landing_url: creative.creative.landing_url,
                            id: creative.creative.id,
                            type_id: creative.creative.type_id,
                            type_label: creative.creative.type_label,
                            status_id: creative.creative.status_id,
                            status_label: creative.creative.status_label
                        });

                        creative.creative.additional_headlines?.forEach((item: string) => {
                            this.formService.searchHeadlinesFormArray.push(this.fb.control(item));
                        })

                        creative.creative.additional_description?.forEach((item: string) => {
                            this.formService.searchDescriptionsFormArray.push(this.fb.control(item));
                        });

                        this.formService.adsCreativeArray.push(this.formService.cloneFormGroup(this.formService.adsFormGroup));
                        this.formService.adsFormGroup.reset();
                        this.formService.searchHeadlinesFormArray.clear();
                        this.formService.searchDescriptionsFormArray.clear();
                    })
                },
                error: _ => this.router.navigate(['/home'])
            })
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    addHeadline() {
        this.searchHeadlinesFormArray.push(this.fb.control('', Validators.maxLength(30)));
    }

    addDescription() {
        this.searchDescriptionsFormArray.push(this.fb.control('', Validators.maxLength(90)));
    }

    createAnotherAd() {
        const step2: SearchStep2Item[] = [{
            creative: {
                headline_1: this.form.get('headline_1')?.value,
                headline_2: this.form.get('headline_2')?.value,
                headline_3: this.form.get('headline_3')?.value,
                description_1: this.form.get('description_1')?.value,
                description_2: this.form.get('description_2')?.value,
                additional_headlines: this.searchHeadlinesFormArray.value,
                additional_descriptions: this.searchDescriptionsFormArray.value,
                landing_url: this.form.get('landing_url')?.value
            }
        }]
        const campaign: CampaignSteps = { step2 };

        this.subs.add(this.utilityService.updateAnCampaignStepEffect(campaign).subscribe({
            next: v => {
                this.errorService.searchAdErrorSignal.set(null);
                const data: SearchCreativeItem = v.campaign.step2[0].creative;
                this.form.get('id')?.setValue(data.id);
                this.form.get('type_id')?.setValue(data.type_id);
                this.form.get('type_label')?.setValue(data.type_label);
                this.form.get('status_id')?.setValue(data.status_id);
                this.form.get('status_label')?.setValue(data.status_label);
                this.formService.adsCreativeArray.push(this.formService.cloneFormGroup(this.form));
                this.form.reset();
                this.searchHeadlinesFormArray.clear();
                this.searchDescriptionsFormArray.clear();
            },
            error: (error: HttpErrorResponse) => {
                console.error('Search Ad Error:', error);
                if (error.status !== 422 || !error.error.errors) {
                    this.errorService.searchAdErrorSignal.set({ generalError: `${error.status}: ${this.translateService.instant(error.statusText)}` });
                    return;
                }
                const display: SearchAdsErrorInterface = error.error.errors.step2[0];
                this.errorService.searchAdErrorSignal.set(display);
            }
        }))
    }

}