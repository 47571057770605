import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { AdvocateOption } from '../../models';

@Component({
    selector: 'advocate-ui-pills',
    templateUrl: 'pills.component.html',
    styleUrls: ['pills.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatChipsModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule
    ]
})
export class PillsComponent {
    @Input() chips: AdvocateOption[] | null = null;
    @Input() form: FormGroup = new FormGroup({});
    @Input() controlArray: FormArray = this.fb.array([]);

    constructor(private fb: FormBuilder) { }

    updateControlArray(selectedChips: AdvocateOption[]): void {
        // First, clear the array
        this.controlArray.clear();

        // Then add each selected chip to the controlArray
        selectedChips.forEach(chip => {
            // if (chip.label !== 'All') { // Don't add 'All' if individual pills are selected
            this.controlArray.push(this.fb.control(chip));
            // }
        });
    }

    // TODO: Fix performance issue on using isSelected
    isSelected(chip: AdvocateOption): boolean {
        return this.controlArray.value.some((control: AdvocateOption) => control?.id === chip?.id);
    }

    trackByFn(index: number, item: AdvocateOption) {
        return item.id; // or any other unique property of your ChipsModel
    }
}
