<div *ngIf="!showMatTooltip" class="info-icon-container" (mouseenter)="showInfo = true" (mouseleave)="showInfo = false">
  <advocate-icon size="small" state="primary" name="info"></advocate-icon>
  <ng-container *ngIf="showInfo">
    <div class="info-tooltip"
      [style.transform]="'translateX(' + translateXPercentage + '%) translateY(' + translateYPercentage + '%)'"
      [innerHTML]="sanitizedInfoText"></div>
  </ng-container>
</div>


<div *ngIf="showMatTooltip" class="info-tooltip-container" matTooltip [matTooltip]="infoText"
  [matTooltipDisabled]="disabled" [matTooltipHideDelay]="hideDelay" [matTooltipPosition]="position"
  [matTooltipPositionAtOrigin]="positionAtOrigin" [matTooltipShowDelay]="showDelay" [matTooltipClass]="tooltipClass">
  <advocate-icon size="small" state="primary" name="info"></advocate-icon>
</div>