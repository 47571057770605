<mat-expansion-panel class="container" [ngClass]="bgColor" [expanded]="isExpanded" [hideToggle]="hideToggle">
  <mat-expansion-panel-header class="container-header" [collapsedHeight]="collapsedHeight"
    [expandedHeight]="expandedHeight">
    <mat-panel-title class="container-header--title">{{ title }}
      <ng-container *ngIf="infoIcon">
        &nbsp;
        <advocate-ui-info-icon [infoText]="hoverText" />
      </ng-container>
      <ng-container *ngIf="optional">
        &nbsp;
        <i>({{ "GENERAL.OPTIONAL" | translate }})</i>
      </ng-container>
    </mat-panel-title>

    <mat-panel-description>
      {{ description }}
    </mat-panel-description>
  </mat-expansion-panel-header>
  <ng-content select="[renderComponent]"></ng-content>
</mat-expansion-panel>