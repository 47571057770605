import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, debounceTime, distinctUntilChanged, map, of, startWith, switchMap } from 'rxjs';
import { AdvocateOption, ChipsModel, LoctionsResponse, ProximityErrorResponse, TargetingErrorInterface } from '../../../../models';
import { FormService } from '../../../../services/form.service';
import { ButtonComponent } from '../../../../shared/button/button.component';
import { ChipsComponent } from '../../../../shared/chips/chips.component';
import { DropDownComponent } from '../../../../shared/dropdown/dropdown.component';
import { IconComponent } from '../../../../shared/icon/icon.component';
import { InfoIconComponent } from '../../../../shared/info-icon/info-icon.component';
import { OrDividerComponent } from '../../../../shared/or-divider/or-divider.component';
import { PageDescriptionComponent } from '../../../../shared/page-description/page-description.component';
import { BackendErrorTranslate } from '../../../../shared/pipes/backend-error-i18n.pipe';
import { SliderComponent } from '../../../../shared/slider/slider.component';
import { ErrorHandlingViewModelService } from '../../../view-model/error-handling-vm.service';
import { UtilityViewModelService } from '../../../view-model/utility-vm.service';
import { BulkPostalCodesComponent } from '../bulk-postal-codes/bulk-postal-codes.component';
import { ProximityComponent } from '../proximity/proximity.component';

@Component({
    selector: 'advocate-ui-geography-search',
    standalone: true,
    imports: [CommonModule, ButtonComponent, IconComponent, MatFormFieldModule, DropDownComponent, SliderComponent, ProximityComponent, DropDownComponent,
        ChipsComponent, TranslateModule, OrDividerComponent, BackendErrorTranslate, InfoIconComponent, ReactiveFormsModule, BulkPostalCodesComponent, PageDescriptionComponent],
    templateUrl: './geography-search-page.component.html',
    styleUrls: ['./geography-search-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeographySearchComponent {
    @Input() proximityUnitType!: AdvocateOption[] | null;
    @Input() targetError!: TargetingErrorInterface | null;

    protected liveSearchControl = new FormControl('');
    form: FormGroup = this.formService.searchTargetingFormGroup;
    proximityFormGroup: FormGroup = this.formService.proximityFormGroup;
    proximityError$: Observable<ProximityErrorResponse | null> = this.errorService.getProximityError();
    locationsArray: FormArray = this.formService.searchLocationsArray;
    postalCodeCountriesArray: FormArray = this.formService.searchPostalCodeCountriesArray;

    locations$: Observable<AdvocateOption[] | null> = this.liveSearchControl.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(input => {
            return input && input.length > 2
                ? this.utilityService.getLocationsEffect(input).pipe(map((res: LoctionsResponse | null) => {
                    if (!res) return null;
                    return res.locations
                }))
                : of(null)
        }));

    chips$: Observable<ChipsModel[]> = this.locationsArray.valueChanges.pipe(
        startWith(this.locationsArray.value),
        map((values: { id: number; name?: string; value?: string }[]) => {
            return values.map((m: any) => {
                return {
                    id: m.id,
                    label: m.value ? m.value : m.name
                }
            })
        }));

    constructor(private formService: FormService, private errorService: ErrorHandlingViewModelService, private utilityService: UtilityViewModelService) { }

    clearZipCodes(): void {
        this.form.get('postalCodes')?.reset();
    }
}
