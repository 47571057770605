import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, WritableSignal } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { StepperComponent } from '../../../../shared/stepper/stepper.component';

import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { TranslateModule } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { Subscription, catchError, filter, of, switchMap, take, tap } from 'rxjs';
import { AdvocateOption, CampaignItemInfo, CampaignSteps, SearchAdsErrorInterface, SearchBudgetErrorInterface, SearchCreativeItem, SearchStep2Item, SearchStep3, SearchStep4, Step1ErrorInterface, Step5, TabOptions } from '../../../../models';
import { CampaignsService } from '../../../../services/campaigns.service';
import { FormService } from '../../../../services/form.service';
import { InternalService } from '../../../../services/internal.service';
import { ButtonComponent } from '../../../../shared/button/button.component';
import { ErrorHandlingViewModelService } from '../../../view-model/error-handling-vm.service';
import { UtilityViewModelService } from '../../../view-model/utility-vm.service';
import { AdsPageComponent } from '../../ads-page/ads-page.component';
import { BudgetSearchPageComponent } from '../../budget-page/budget-search-page.component';
import { BudgetOverspendErrorDialogComponent } from '../../budget-page/dialog/budget-overspend-error-dialog/budget-overspend-error-dialog.component';
import { BudgetOverspendWarningDialogComponent } from '../../budget-page/dialog/budget-overspend-warning-dialog/budget-overspend-warning-dialog.component';
import { CampaignPageComponent } from '../../campaign-page/campaign-page.component';
import { CreativeDetailComponent } from '../../creatives-page/creative-detail/creative-detail.component';
import { KeywordsPageComponent } from '../../keywords-page/keywords-page.component';
import { ObjectivePageComponent } from '../../objective-page/objective-page.component';
import { SummarySearchPageComponent } from '../../summary-page/search/summary-search-page.component';
import { SummaryDialogComponent } from '../../summary-page/summary-dialog.component';
import { TargetingDetailComponent } from '../../targeting-page/targeting-detail/targeting-detail.component';
import { TargetingSearchPageComponent } from '../../targeting-page/targeting-search-page.component';

@Component({
  selector: 'advocate-ui-search-campaign-stepper',
  standalone: true,
  imports: [
    AdsPageComponent,
    CommonModule,
    StepperComponent,
    MatStepperModule,
    CampaignPageComponent,
    ObjectivePageComponent,
    BudgetSearchPageComponent,
    SummarySearchPageComponent,
    KeywordsPageComponent,
    TargetingSearchPageComponent,
    ButtonComponent,
    TranslateModule,
    CreativeDetailComponent,
    TargetingDetailComponent
  ],
  templateUrl: './search-campaign-stepper.component.html',
  styleUrls: ['./search-campaign-stepper.component.scss'],
})
export class SearchCampaignStepperComponent implements OnInit {
  @ViewChild(MatStepper, { static: true }) stepper!: MatStepper;
  currentStep: number = 0;
  campaignNameFormGroup: FormGroup = this.formService.searchCampaignNameFormGroup;
  private subs = new Subscription();
  protected selectedTabSignal: WritableSignal<TabOptions | null> = this.internalService.selectedTabSignal;

  constructor(
    private dialog: MatDialog,
    private internalService: InternalService,
    private router: Router,
    private formService: FormService,
    private utilityService: UtilityViewModelService,
    private errorService: ErrorHandlingViewModelService,
    private campaignService: CampaignsService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.campaignService.campaignIdSignal.set(id ? +id : null);
    // Set the initial step based on the URL
    const step = this.getStepFromUrl(this.router.url); // Get the initial step from the URL
    if (step !== null) {
      this.stepper.selectedIndex = this.currentStep = step;
    }

    // Listen for route changes to determine which step to show
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      const step = this.getStepFromUrl(event.url);
      if (step !== null) {
        this.currentStep = step;
        if (this.stepper) {
          this.stepper.selectedIndex = this.currentStep;  // Update the selected step in the stepper
        }
      }
    });
  }

  getStepFromUrl(urlSegments: string): number | null {
    const stepPattern = /step(\d+)/; // Matches "step" followed by a number
    const match = urlSegments.match(stepPattern); // Match against the full URL string
    if (match) {
      return parseInt(match[1], 10) - 1; // Extract the step number and convert to index (step1 -> 0)
    }
    return null; // Return null if no match is found
  }

  goToPreviousStep() {
    if (this.currentStep > 0) {
      this.router.navigate([`/home/search/${this.campaignService.campaignIdSignal()}/step${this.currentStep}`]); // Previous step URL
      this.stepper.previous();
    }
  }

  onSaveDraft(): void {
    this.router.navigate(['/home/reports']);
  }

  isNextDisabled(stepperNum: number): boolean {
    switch (stepperNum) {
      case 0: return this.campaignNameFormGroup.invalid;
      case 1: return false;
      case 2: return this.formService.searchBudgetGroup?.invalid
      case 3: return false;
      case 4: return this.formService.searchTargetingFormGroup?.invalid;
      default: return true;
    }
  }

  navigateTo(_route: string): void {
    this.router.navigate([_route]);
  }

  onStepChange(event: StepperSelectionEvent): void {
    this.currentStep = event.selectedIndex
    this.router.navigate([`/home/search/${this.campaignService.campaignIdSignal()}/step${this.currentStep + 1}`]);
  }

  openDialog(): void {
    this.utilityService.getCampaignDataEffect(this.campaignService.campaignIdSignal()!)
      .pipe(
        take(1),
        switchMap((campaignItemInfo: CampaignItemInfo) => {
          if (campaignItemInfo.campaign.misc.top_up_suggestion > 0) {
            this.dialog.open(BudgetOverspendErrorDialogComponent, {
              width: '60rem',
              minHeight: '40rem',
              maxHeight: '74.5rem'
            });
            return of(null)
          } else {
            return this.utilityService.actionCampaignEffect('publish').pipe(
              tap(_ => {
                this.errorService.publishErrorSignal.set(null);
                this.dialog.open(SummaryDialogComponent, {
                  width: '64rem',
                  height: '33.3rem',
                  enterAnimationDuration: '0ms',
                  exitAnimationDuration: '0ms',
                });
              }),
              catchError((error: HttpErrorResponse) => {
                this.errorService.publishErrorSignal.set(JSON.parse(error.error.message));
                return of(null);
              })
            )
          }
        })).subscribe();
  }

  searchCreativeStepper(): void {
    if (this.formService.adsFormGroup.valid) {
      const step2: SearchStep2Item[] = [{
        creative: {
          headline_1: this.formService.adsFormGroup.get('headline_1')?.value,
          headline_2: this.formService.adsFormGroup.get('headline_2')?.value,
          headline_3: this.formService.adsFormGroup.get('headline_3')?.value,
          description_1: this.formService.adsFormGroup.get('description_1')?.value,
          description_2: this.formService.adsFormGroup.get('description_2')?.value,
          additional_headlines: this.formService.searchHeadlinesFormArray.value,
          additional_descriptions: this.formService.searchDescriptionsFormArray.value,
          landing_url: this.formService.adsFormGroup.get('landing_url')?.value
        }
      }]
      const campaign: CampaignSteps = { step2 };

      this.subs.add(this.utilityService.updateAnCampaignStepEffect(campaign).subscribe({
        next: (v: CampaignSteps) => {
          this.stepper.selected!.completed = true;
          this.routeToNextStep();
          this.errorService.searchAdErrorSignal.set(null);
          if (!Array.isArray(v.step2)) return;
          const data: SearchCreativeItem = v.step2[0].creative;
          this.formService.adsFormGroup.get('id')?.setValue(data.id);
          this.formService.adsFormGroup.get('type_id')?.setValue(data.type_id);
          this.formService.adsFormGroup.get('type_label')?.setValue(data.type_label);
          this.formService.adsFormGroup.get('status_id')?.setValue(data.status_id);
          this.formService.adsFormGroup.get('status_label')?.setValue(data.status_label);
          this.formService.adsCreativeArray.push(this.formService.cloneFormGroup(this.formService.adsFormGroup));
          this.formService.adsFormGroup.reset();
        },
        error: (error: HttpErrorResponse) => {
          console.error('Search Ad Error:', error);
          if (error.status !== 422 || !error.error.errors) {
            this.errorService.searchAdErrorSignal.set({ generalError: `${error.status}: ${error.statusText}` });
            return;
          }
          const display: SearchAdsErrorInterface = error.error.errors.step2[0];
          this.errorService.searchAdErrorSignal.set(display);
        }
      }));
    } else {
      this.stepper.selected!.completed = true;
      this.routeToNextStep();
    }
  }

  searchBudgetStepper(): void {
    const step3: SearchStep3 = {
      cpc_bid: this.formService.searchBudgetGroup.get('cpc_bid')?.value,
      daily_budget: this.formService.searchBudgetGroup.get('daily_budget')?.value,
      end_date: moment(this.formService.searchBudgetGroup.get('end_date')?.value).format('YYYY-MM-DD'),
      start_date: moment(this.formService.searchBudgetGroup.get('start_date')?.value).format('YYYY-MM-DD'),
    };

    const campaignStep: CampaignSteps = { step3 };
    this.subs.add(this.utilityService.updateAnCampaignStepEffect(campaignStep).pipe(
      switchMap((_) => {
        return this.utilityService.getCampaignDataEffect(this.campaignService.campaignIdSignal()!).pipe(
          switchMap((campaignInfo: CampaignItemInfo) => {
            // Open the budget warning modal if necessary
            if (campaignInfo.campaign.misc.top_up_suggestion > 0) {
              const dialogRef = this.dialog.open(BudgetOverspendWarningDialogComponent, {
                width: '65rem',
                minHeight: '40rem',
                maxHeight: '76rem',
                data: { misc: campaignInfo.campaign.misc }
              });

              dialogRef.afterClosed().subscribe((isLater: boolean) => {
                if (isLater) {
                  this.errorService.searchBudgetErrorSignal.set(null);
                  this.stepper.selected!.completed = true;
                  this.routeToNextStep();
                  return of(null);
                } else return of(null);
              })
            } else {
              this.errorService.searchBudgetErrorSignal.set(null);
              this.stepper.selected!.completed = true;
              this.routeToNextStep();
            }
            return of(null);
          }))
      })

    )
      .subscribe({
        error: (error: HttpErrorResponse) => {
          console.error('Search Budget Error:', error);
          if (error.status !== 422 || !error.error.errors) {
            this.errorService.searchBudgetErrorSignal.set({ generalError: `${error.status}: ${error.statusText}` });
            return;
          }
          const display: SearchBudgetErrorInterface = error.error.errors.step3;
          this.errorService.searchBudgetErrorSignal.set(display);
        }
      }))
  }

  searchKeywordStepper(): void {
    const step4: SearchStep4 = {
      keywords: this.formService.targetingFormArray.value,
      negative_keywords: this.formService.negativeFormArray.value
    };
    const campaignStep: CampaignSteps = { step4 };
    this.subs.add(this.utilityService.updateAnCampaignStepEffect(campaignStep).subscribe(() => {
      this.stepper.selected!.completed = true;
      this.routeToNextStep();
    }))
  }

  searchTargetStepper(): void {
    const locations: AdvocateOption[] = this.formService.searchLocationsArray.value;
    const languages: AdvocateOption[] = this.formService.searchLanguagesArray.value;
    const deviceGroups: AdvocateOption[] = this.formService.searchDevicesGroupsArray.value;
    const step5: Step5 = {
      locations: locations.map((m: AdvocateOption) => {
        return { id: m.id as number, name: m.name }
      }),
      languages: languages.map((m: AdvocateOption) => {
        return { id: m.id as number, name: m.name }
      }),
      proximity_targets: this.formService.searchProximityFormArray.value,
      devices: Array.from(new Set(deviceGroups.map(device => device.name))) ?? [],
    };
    const campaignStep: CampaignSteps = { step5 };
    this.subs.add(this.utilityService.updateAnCampaignStepEffect(campaignStep).subscribe(
      () => {
        this.utilityService.initCampaignForecastData();
        this.stepper.selected!.completed = true;
        this.routeToNextStep()
      }));
  }

  goToNextStep(stepperNum: number) {
    if (stepperNum === 0) {
      const campaignStep: CampaignSteps = {
        step1: {
          advertiser_id: this.formService.searchCampaignNameFormGroup.get('selectedAdvertiser')?.value?.id,
          name: this.formService.searchCampaignNameFormGroup.get('campaignName')?.value
        }
      };
      this.subs.add(this.utilityService.updateAnCampaignStepEffect(campaignStep).subscribe({
        next: () => {
          this.errorService.campaignStepErrorSignal.set(null);
          this.stepper.selected!.completed = true;
          this.routeToNextStep();
        },
        error: (error: HttpErrorResponse) => {
          console.error('updateAnCampaignStep1Effect', error);
          if (error.status !== 422 || !error.error.errors) {
            this.errorService.campaignStepErrorSignal.set({ generalError: `${error.status}: ${error.statusText}` });
            return;
          }
          const display: Step1ErrorInterface = error.error.errors.step1;
          this.errorService.campaignStepErrorSignal.set(display);
        }
      }));
    } else if (stepperNum === 1) {
      this.searchCreativeStepper();
    } else if (stepperNum === 2) {
      this.searchBudgetStepper();
    } else if (stepperNum === 3) {
      this.searchKeywordStepper();
    } else if (stepperNum === 4) {
      this.searchTargetStepper();
    }
    else {
      this.stepper.selected!.completed = true;
      this.routeToNextStep();
    }
  }

  routeToNextStep(): void {
    this.router.navigate([`/home/search/${this.campaignService.campaignIdSignal()}/step${this.currentStep + 2}`]); // Next step URL
    this.stepper.next();
  }
}
