<div class="container">
  <div class="breadcrumbs">
    <span class="breadcrumbs--in-active" (click)="navigateTo('/')">Home</span>
    <span class="breadcrumbs--active">/</span>
    <span class="breadcrumbs--in-active" (click)="navigateTo('/home/campaign')">New Campaign</span>
    <span class="breadcrumbs--active">/</span>
    <span class="breadcrumbs--active">Search</span>
  </div>

  <div class="container-stepper">
    <div class="container-stepper--main">
      <h2>{{selectedTabSignal()?.tabName}}</h2>
      <mat-stepper #stepper [linear]="false" [selectedIndex]="currentStep" labelPosition="bottom" class="stepper"
        (selectionChange)="onStepChange($event)">

        <!-- Step 1 -->
        <mat-step label="Campaign">
          <advocate-ui-campaign-page *ngIf="stepper.selectedIndex === 0" />
        </mat-step>

        <!-- Step 2 -->
        <mat-step label="ads">
          <advocate-ui-ads-page *ngIf="stepper.selectedIndex === 1" />
        </mat-step>

        <!-- Step 3 -->
        <mat-step label="Budget & Dates">
          <advocate-ui-budget-search-page *ngIf="stepper.selectedIndex === 2" />
        </mat-step>

        <!-- Step 4 -->
        <mat-step label="Keywords">
          <advocate-ui-keywords-page *ngIf="stepper.selectedIndex === 3" />
        </mat-step>

        <!-- Step 5 -->
        <mat-step label="Targeting">
          <advocate-ui-targeting-search-page *ngIf="stepper.selectedIndex === 4" />
        </mat-step>

        <!-- Step 6 -->
        <mat-step label="Summary">
          <advocate-ui-summary-search-page *ngIf="stepper.selectedIndex === 5" />
        </mat-step>

      </mat-stepper>

      <!-- Navigation Buttons -->
      <div class="stepper-buttons">
        <ng-container *ngIf="stepper.selectedIndex !== 5; else finalStepperButtonRef">
          <advocate-ui-button *ngIf="stepper.selectedIndex !== 0" [isCurved]="true" size="medium"
            [name]="'GENERAL.BACK' | translate" state="secondary" (onChange)="goToPreviousStep()"></advocate-ui-button>
          <advocate-ui-button data-cy="stepper-next" [isCurved]="true" size="medium" [name]="'GENERAL.NEXT' | translate"
            [state]="isNextDisabled(stepper.selectedIndex) ? 'Disabled' : 'primary'"
            [disabled]="isNextDisabled(stepper.selectedIndex)"
            (onChange)="goToNextStep(stepper.selectedIndex)"></advocate-ui-button>
        </ng-container>

        <ng-template #finalStepperButtonRef>
          <advocate-ui-button [isCurved]="true" size="medium" [name]="'GENERAL.SAVE_DRAFT' | translate"
            state="secondary" (onChange)="onSaveDraft()"></advocate-ui-button>
          <advocate-ui-button data-cy="stepper-publish" [isCurved]="true" size="medium"
            [name]="'GENERAL.PUBLISH' | translate" (onChange)="openDialog()"></advocate-ui-button>
        </ng-template>
      </div>
    </div>

  </div>