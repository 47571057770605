import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../icon/icon.component';
import { InfoIconComponent } from '../info-icon/info-icon.component';

@Component({
  selector: 'advocate-ui-expansion-panel',
  standalone: true,
  imports: [CommonModule, MatExpansionModule, IconComponent, InfoIconComponent, TranslateModule],
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
})
export class ExpansionPanelComponent {
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() content: string = '';
  @Input() isExpanded: boolean = false;
  @Input() hideToggle: boolean = false;
  @Input() collapsedHeight: string = 'default';
  @Input() expandedHeight: string = 'default';
  @Input() infoIcon: boolean = false;
  @Input() optional: boolean = false;
  @Input() applyColor: boolean = true;
  @Input() hoverText: string = '';
  @Input() bgColor: string = '';
}
