import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';
import { InternalService } from '../../services/internal.service';
import { AuthService } from '../auth/auth.service';

@Component({
    standalone: true,
    imports: [CommonModule],
    selector: 'advocate-ui-impersonate-admin',
    template: ''
})
export class ImpersonateAdminComponent implements OnInit {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private internalService: InternalService
    ) { }

    ngOnInit(): void {
        const userId = this.route.snapshot.paramMap.get('userId') ?? '';
        this.authService.impersonateUser(userId).pipe(take(1)).subscribe({
            next: (_) => {
                this.router.navigate(['/home']);
                this.internalService.showMessage(`Impersonated the user ${_?.user.name}. Please logout to go back to admin login!`);
            },
            error: (error: HttpErrorResponse) => {
                let message: string;
                switch (error.status) {
                    case 500: message = `Could not find user ID ${userId}`;
                        break;
                    case 403: message = 'Please login as Admin to avail impersonate feature';
                        break;
                    case 401: message = 'Please login first!';
                        break;
                    default: message = 'Failed loading, please try again';
                        break;
                }
                this.internalService.showMessage(message);
                this.router.navigate(['/home']);
            }
        });
    }
} 