<div class="container">
    <h2 class="container_header">{{ "ADS.TITLE" | translate }}</h2>
    <form [formGroup]="form" class="advocate-form">
        <div class="advocate-form-row">
            <div class="advocate-form-row__label advocate-form-label">
                <label for="headline-1" class="advocate-form-label__content">{{"ADS.HEADLINE_1" | translate}}</label>
                <span class="advocate-required-field"></span>
            </div>

            <div class="advocate-form-row__content">
                <input data-cy="ads_headline1" type="text" id="headline-1" autocomplete="off"
                    formControlName="headline_1" class="cux-textbox cux-textbox--stretched" [ngClass]="{'cux-textbox--error': (searchAdErrorSignal() && searchAdErrorSignal()?.headline_1?.[0]) 
                    || form.get('headline_1')?.errors?.['maxlength'] || form.errors?.['nonUniqueHeadlines']}">
            </div>
            <div class="advocate-help-text">
                <p>{{"ADS.HEADLINE_1_MAX_CHAR" | translate}}</p>
            </div>
            <div class="advocate-help-text" data-cy="ads_headline1_error_be"
                *ngIf="searchAdErrorSignal() && searchAdErrorSignal()?.headline_1?.[0]">
                <p class="advocate-help-text--error">{{ searchAdErrorSignal()?.headline_1![0] | ErrorTranslate}}</p>
            </div>
            <div class="advocate-help-text" data-cy="ads_headline1_error_maxchar"
                *ngIf="form.get('headline_1')?.errors?.['maxlength']">
                <p class="advocate-help-text--error">{{"ADS.HEADLINE_MAX_CHAR" | translate}}</p>
            </div>
            <div class="advocate-help-text" data-cy="ads_headline1_error_nonunique"
                *ngIf="form.errors?.['nonUniqueHeadlines']">
                <p class="advocate-help-text--error">{{"ADS.HEADLINE_MUST_BE_UNIQUE" | translate}}</p>
            </div>
        </div>

        <div class="advocate-form-row">
            <div class="advocate-form-row__label advocate-form-label">
                <label for="headline-2" class="advocate-form-label__content">{{"ADS.HEADLINE_2" | translate}}</label>
                <span class="advocate-required-field"></span>
            </div>

            <div class="advocate-form-row__content">
                <input data-cy="ads_headline2" type="text" id="headline-2" autocomplete="off"
                    formControlName="headline_2" class="cux-textbox cux-textbox--stretched" [ngClass]="{'cux-textbox--error': (searchAdErrorSignal() && searchAdErrorSignal()?.headline_2?.[0]) 
                || form.get('headline_2')?.errors?.['maxlength'] || form.errors?.['nonUniqueHeadlines']}">
            </div>
            <div class="advocate-help-text">
                <p>{{"ADS.HEADLINE_2_MAX_CHAR" | translate}}</p>
            </div>
            <div class="advocate-help-text" *ngIf="searchAdErrorSignal() && searchAdErrorSignal()?.headline_2?.[0]">
                <p class="advocate-help-text--error">{{ searchAdErrorSignal()?.headline_2![0] | ErrorTranslate}}</p>
            </div>
            <div class="advocate-help-text" *ngIf="form.get('headline_2')?.errors?.['maxlength']">
                <p class="advocate-help-text--error">{{"ADS.HEADLINE_MAX_CHAR" | translate}}</p>
            </div>
            <div class="advocate-help-text" *ngIf="form.errors?.['nonUniqueHeadlines']">
                <p class="advocate-help-text--error">{{"ADS.HEADLINE_MUST_BE_UNIQUE" | translate}}</p>
            </div>
        </div>

        <div class="advocate-form-row">
            <div class="advocate-form-row__label advocate-form-label">
                <label for="headline-3" class="advocate-form-label__content">{{"ADS.HEADLINE_3" | translate}}</label>
                <span class="advocate-required-field"></span>
            </div>

            <div class="advocate-form-row__content">
                <input data-cy="ads_headline3" type="text" id="headline-3" autocomplete="off"
                    formControlName="headline_3" class="cux-textbox cux-textbox--stretched" [ngClass]="{'cux-textbox--error': (searchAdErrorSignal() && searchAdErrorSignal()?.headline_3?.[0]) || 
                form.get('headline_3')?.errors?.['maxlength'] || form.errors?.['nonUniqueHeadlines']}">
            </div>
            <div class="advocate-help-text">
                <p>{{"ADS.HEADLINE_3_MAX_CHAR" | translate}}</p>
            </div>
            <div class="advocate-help-text" *ngIf="searchAdErrorSignal() && searchAdErrorSignal()?.headline_3?.[0]">
                <p class="advocate-help-text--error">{{ searchAdErrorSignal()?.headline_3![0] | ErrorTranslate}}</p>
            </div>
            <div class="advocate-help-text" *ngIf="form.get('headline_3')?.errors?.['maxlength']">
                <p class="advocate-help-text--error">{{"ADS.HEADLINE_MAX_CHAR" | translate}}</p>
            </div>
            <div class="advocate-help-text" *ngIf="form.errors?.['nonUniqueHeadlines']">
                <p class="advocate-help-text--error">{{"ADS.HEADLINE_MUST_BE_UNIQUE" | translate}}</p>
            </div>
        </div>

        <div *ngIf="searchHeadlinesFormArray?.controls && searchHeadlinesFormArray.controls.length"
            formArrayName="additional_headlines" class="advocate-form-row">
            <div *ngFor="let headline of searchHeadlinesFormArray?.controls; let i = index" class="advocate-form-row">
                <div class="advocate-form-row__label advocate-form-label">
                    <label [attr.for]="'headline-' + i" class="advocate-form-label__content">{{"CREATIVE.HEADLINE" |
                        translate}} {{i + 4}}</label>
                </div>

                <div class="advocate-form-row__content">
                    <input [attr.data-cy]="'ads_additional_headlines'" type="text" [id]="'headline-' + i"
                        autocomplete="off" [formControlName]="i" class="cux-textbox cux-textbox--stretched"
                        [ngClass]="{'cux-textbox--error': headline.errors?.['maxlength'] || form.errors?.['nonUniqueHeadlines']}">
                </div>
                <div class="advocate-help-text">
                    <p>{{"CREATIVE.HEADLINE" | translate}} {{i + 4}} {{"ADS.HEADLINE_MAX_CHAR" | translate}}</p>
                </div>
                <div class="advocate-help-text" *ngIf="headline.errors?.['maxlength']">
                    <p class="advocate-help-text--error">{{"ADS.HEADLINE_MAX_CHAR" | translate}}</p>
                </div>
                <div class="advocate-help-text" *ngIf="form.errors?.['nonUniqueHeadlines']">
                    <p class="advocate-help-text--error">{{"ADS.HEADLINE_MUST_BE_UNIQUE" | translate}}</p>
                </div>
            </div>
        </div>

        <advocate-ui-button data-cy="ads_add_headline_button" [isCurved]="true" state="secondary" size="large"
            (onChange)="addHeadline()" [name]="'ADS.ADD_NEW_HEADLINE' | translate"></advocate-ui-button>

        <div class="advocate-form-row mt-2">
            <div class="advocate-form-row__label advocate-form-label">
                <label for="description-1" class="advocate-form-label__content">{{ "ADS.DESCRIPTION_1" | translate
                    }}</label>
                <span class="advocate-required-field"></span>
            </div>

            <div class="advocate-form-row__content">
                <input data-cy="ads_description1" type="text" id="description-1" autocomplete="off"
                    formControlName="description_1" class="cux-textbox cux-textbox--stretched"
                    [ngClass]="{'cux-textbox--error': (searchAdErrorSignal() && searchAdErrorSignal()?.description_1?.[0]) || form.errors?.['nonUniqueDescriptions']}">
            </div>
            <div class="advocate-help-text" data-cy="ads_description1_error_maxchar">
                <p>{{ "ADS.DESCRIPTION_1_MAX_CHAR" | translate }}</p>
            </div>
            <div class="advocate-help-text" data-cy="ads_description1_error_be"
                *ngIf="searchAdErrorSignal() && searchAdErrorSignal()?.description_1?.[0]">
                <p class="advocate-help-text--error">{{ searchAdErrorSignal()?.description_1![0] | ErrorTranslate }}
                </p>
            </div>
            <div class="advocate-help-text" data-cy="ads_description1_error_nonunique"
                *ngIf="form.errors?.['nonUniqueDescriptions']">
                <p class="advocate-help-text--error">{{ "ADS.DESCRIPTION_MUST_BE_UNIQUE" | translate }}</p>
            </div>
            <div class="advocate-help-text" *ngIf="form.get('description_1')?.errors?.['maxlength']">
                <p class="advocate-help-text--error">{{"ADS.DESCRIPTION_MAX_CHAR" | translate}}</p>
            </div>
        </div>

        <div class="advocate-form-row">
            <div class="advocate-form-row__label advocate-form-label">
                <label for="description-2" class="advocate-form-label__content">{{ "ADS.DESCRIPTION_2" | translate
                    }}</label>
                <span class="advocate-required-field"></span>
            </div>

            <div class="advocate-form-row__content">
                <input data-cy="ads_description2" type="text" id="description-2" autocomplete="off"
                    formControlName="description_2" class="cux-textbox cux-textbox--stretched"
                    [ngClass]="{'cux-textbox--error': (searchAdErrorSignal() && searchAdErrorSignal()?.description_2?.[0]) || form.errors?.['nonUniqueDescriptions']}">
            </div>
            <div class="advocate-help-text">
                <p>{{ "ADS.DESCRIPTION_2_MAX_CHAR" | translate }}</p>
            </div>
            <div class="advocate-help-text" *ngIf="searchAdErrorSignal() && searchAdErrorSignal()?.description_2?.[0]">
                <p class="advocate-help-text--error">{{ searchAdErrorSignal()?.description_2![0] | ErrorTranslate}}</p>
            </div>
            <div class="advocate-help-text" *ngIf="form.errors?.['nonUniqueDescriptions']">
                <p class="advocate-help-text--error">{{ "ADS.DESCRIPTION_MUST_BE_UNIQUE" | translate }}</p>
            </div>
            <div class="advocate-help-text" *ngIf="form.get('description_2')?.errors?.['maxlength']">
                <p class="advocate-help-text--error">{{"ADS.DESCRIPTION_MAX_CHAR" | translate}}</p>
            </div>
        </div>

        <div formArrayName="additional_descriptions" class="advocate-form-row">
            <div *ngFor="let description of searchDescriptionsFormArray?.controls; let i = index"
                class="advocate-form-row">
                <div class="advocate-form-row__label advocate-form-label">
                    <label [attr.for]="'description-' + i" class="advocate-form-label__content">{{ "GENERAL.DESCRIPTION"
                        | translate }} {{i + 3}}</label>
                </div>

                <div class="advocate-form-row__content">
                    <input [attr.data-cy]="'ads_additional_descriptions'" type="text" [id]="'description-' + i"
                        autocomplete="off" [formControlName]="i" class="cux-textbox cux-textbox--stretched"
                        [ngClass]="{'cux-textbox--error': form.errors?.['nonUniqueDescriptions']}">
                </div>
                <div class="advocate-help-text">
                    <p>{{ "GENERAL.DESCRIPTION" | translate }} {{i + 3}} {{ "ADS.DESCRIPTION_MAX_CHAR" | translate }}
                    </p>
                </div>
                <div class="advocate-help-text" *ngIf="form.errors?.['nonUniqueDescriptions']">
                    <p class="advocate-help-text--error">{{ "ADS.DESCRIPTION_MUST_BE_UNIQUE" | translate }}</p>
                </div>
                <div class="advocate-help-text" *ngIf="description.errors?.['maxlength']">
                    <p class="advocate-help-text--error">{{"ADS.DESCRIPTION_MAX_CHAR" | translate}}</p>
                </div>
            </div>
        </div>

        <advocate-ui-button data-cy="ads_add_description_button" [isCurved]="true" state="secondary" size="large"
            (onChange)="addDescription()" name="Add new description"></advocate-ui-button>

        <div class="advocate-form-row mt-2">
            <div class="advocate-form-row__label advocate-form-label">
                <label for="url" class="advocate-form-label__content">{{ "CREATIVE.LANDING_PAGE_URL" | translate
                    }}</label>
                <span class="advocate-required-field"></span>
            </div>

            <div class="advocate-form-row__content">
                <input data-cy="ads_landing_url" type="text" id="url" autocomplete="off" formControlName="landing_url"
                    class="cux-textbox cux-textbox--stretched" placeholder="http://example.com/something"
                    [ngClass]="{'cux-textbox--error': searchAdErrorSignal() && searchAdErrorSignal()?.landing_url?.[0] && form.get('landing_url')?.errors?.['pattern'] || form.get('landing_url')?.errors?.['maxlength']}">
            </div>
            <div class="advocate-help-text" *ngIf="searchAdErrorSignal() && searchAdErrorSignal()?.landing_url?.[0]">
                <p class="advocate-help-text--error">{{ searchAdErrorSignal()?.landing_url![0] | ErrorTranslate }}</p>
            </div>
            <div class="advocate-help-text" *ngIf="form.get('landing_url')?.errors?.['pattern']">
                <p data-cy="ads_landingPageUrl_error" class="advocate-help-text--error">
                    {{ "STATUS.INVALID_URL" | translate }}</p>
            </div>
            <div class="advocate-help-text" *ngIf="form.get('landing_url')?.errors?.['maxlength']">
                <p class="advocate-help-text--error">{{"ADS.LANDING_URL_MAX_CHAR" | translate}}</p>
            </div>
        </div>

        <div class="btns-row">
            <advocate-ui-button data-cy="create_another_add_button" [isCurved]="true" [disabled]="form.invalid"
                [state]="form.invalid ? 'Disabled' : 'primary'" size="large" (onChange)="createAnotherAd()"
                [name]="'ADS.CREATE_ANOTHER_AD' | translate"></advocate-ui-button>
        </div>
    </form>

    <advocate-ui-ads-table />
</div>