import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, Signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { startWith, take } from 'rxjs';
import { AdvocateCurrencyOptions } from '../../../../models';
import { FormService } from '../../../../services/form.service';
import { ChipsComponent } from '../../../../shared/chips/chips.component';
import { IconComponent } from '../../../../shared/icon/icon.component';
import { BackendErrorTranslate } from '../../../../shared/pipes/backend-error-i18n.pipe';
import { ErrorHandlingViewModelService } from '../../../view-model/error-handling-vm.service';
import { UtilityViewModelService } from '../../../view-model/utility-vm.service';

@Component({
    selector: 'advocate-ui-summary-search-page',
    templateUrl: './summary-search-page.component.html',
    styleUrls: ['./summary-search-page.component.scss'],
    standalone: true,
    imports: [CommonModule, ChipsComponent, IconComponent, MatDividerModule, MatListModule, TranslateModule, BackendErrorTranslate],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummarySearchPageComponent implements OnInit {
    currencySignal: Signal<AdvocateCurrencyOptions | undefined> = toSignal(this.utilityService.getCurrencyEffect());
    publishErrorSignal: WritableSignal<string[] | null> = this.errorService.publishErrorSignal;
    form$ = this.formService.searchForm.valueChanges.pipe(startWith(this.formService.searchForm.value));

    constructor(
        private activatedRoute: ActivatedRoute,
        private formService: FormService,
        private errorService: ErrorHandlingViewModelService,
        private utilityService: UtilityViewModelService,
        private route: Router) { }

    ngOnInit(): void {
        const id = this.activatedRoute.snapshot.paramMap.get('id');

        if (!id) return;
        this.utilityService.getCampaignStepsEffect(+id).pipe(take(1)).subscribe({
            error: _ => this.route.navigate(['/home'])
        })
    }

    changeStepper(stepperNumber: number): void {
        this.route.navigate([`/home/search/step${stepperNumber}`]);
    }
}
