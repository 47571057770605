<div class="container">
  <form class="advocate-form">
    <div class="advocate-form-row">
      <div class="advocate-form-row__label advocate-form-label">
        <label for="device_types">{{ "TARGETING.DEVICE_TYPES" | translate }}</label>
      </div>

      <div class="advocate-form-row__content">
        <advocate-ui-pills data-cy="devices-pills" [form]="form" [controlArray]="devicesArray" [chips]="devices" />
      </div>
    </div>

    <div class="advocate-form-row">
      <div class="advocate-form-row__label advocate-form-label">
        <label for="languages">{{ "TARGETING.LANGUAGES" | translate }}</label>
        &nbsp;<advocate-ui-info-icon *ngIf="selectedTabSignal()?.tabName === PlatformTypeEnum.Programmatic"
          [infoText]="'TARGETING.LANGUAGE_HOVER_TEXT' | translate" />
      </div>

      <div class="advocate-form-row__content">
        <advocate-ui-dropdown data-cy="deviceLanguage-options-dropdown" [multiple]="true" [form]="form"
          [controlArray]="languagesArray" [options]="languages"
          [title]="'TARGETING.SELECT_AN_OPTION' | translate"></advocate-ui-dropdown>
      </div>
      <i>{{ "TARGETING.LANGUAGE_TEXT" | translate }}</i>
      <div class="advocate-row-chips">
        <advocate-ui-chips [chips]="chips$ | async" [controlArray]="languagesArray" [withRemove]="true" />
      </div>
    </div>
  </form>
</div>