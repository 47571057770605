/**
 * https://fonts.google.com/icons
 * 
 * <advocate-icon size="extra-large" state="primary" name="account_circle"></advocate-icon>
 */

import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ButtonSize } from '../button/button-size.enum';
import { UIState } from '../utility';
import { DefaultIconSize } from './icon-size.enum';
import { IconSizePipe } from './icon-size.pipe';
import { IconStatePipe } from './icon-state.pipe';

@Component({
  selector: 'advocate-icon',
  templateUrl: './icon.component.html',
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule, MatIconModule, IconSizePipe, IconStatePipe]
})
export class IconComponent {

  @Input()
  size: ButtonSize = DefaultIconSize;

  @Input()
  state: UIState = UIState.Default;

  @Input()
  type = 'outlined';

  @Input()
  name = '';

  @Input()
  isSVG: boolean = false;

  @Input()
  svgName = '';
}
