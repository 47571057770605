import { LiveAnnouncer } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, WritableSignal } from '@angular/core';
import { FormArray } from '@angular/forms';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { PlatformTypeEnum, ProgrammaticProximityTarget, ProximityTarget, TabOptions } from '../../../models';
import { FormService } from '../../../services/form.service';
import { InternalService } from '../../../services/internal.service';
import { IconComponent } from '../../icon/icon.component';

@Component({
    selector: 'advocate-ui-proximity-table',
    standalone: true,
    imports: [CommonModule, MatTableModule, MatSortModule, IconComponent, TranslateModule],
    templateUrl: './proximity-table.component.html',
    styleUrls: ['./proximity-table.component.scss']
})
export class ProximityTableComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() hideHeader: boolean = false;
    displayedColumns: string[] = ['latitude', 'longitude', 'radius', 'radius_unit_label', 'delete'];
    dataSource = new MatTableDataSource();
    proximityFormArray: FormArray = this.formService.proximityFormArray;
    programmaticProximityFormArray: FormArray = this.formService.programmaticProximityFormArray;
    selectedTabSignal: WritableSignal<TabOptions | null> = this.internalService.selectedTabSignal;
    PlatformTypeEnum = PlatformTypeEnum;
    private subs = new Subscription();
    @Output() proximitySubmitButtonClicked = new EventEmitter<void>();

    constructor(private _liveAnnouncer: LiveAnnouncer, private formService: FormService, private internalService: InternalService) { }

    @ViewChild(MatSort) sort!: MatSort;

    ngOnInit(): void {
        if (this.selectedTabSignal()?.tabName === PlatformTypeEnum.Programmatic) {
            this.dataSource.data = this.formService.programmaticProximityFormArray.value;
            this.subs.add(this.formService.programmaticProximityFormArray.valueChanges.subscribe(v => {
                this.dataSource.data = v;
            }));
        } else {
            this.dataSource.data = this.formService.proximityFormArray.value;
            this.subs.add(this.formService.proximityFormArray.valueChanges.subscribe(v => {
                this.dataSource.data = v;
            }));
        }
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    announceSortChange(sortState: Sort) {
        if (sortState.direction) {
            this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
        } else {
            this._liveAnnouncer.announce('Sorting cleared');
        }
    }

    deleteRow(data: ProximityTarget) {
        if (this.selectedTabSignal()?.tabName === PlatformTypeEnum.Programmatic) {
            const index = this.programmaticProximityFormArray.value.findIndex((item: ProgrammaticProximityTarget) => item.id === data.id);

            if (index !== -1) {
                this.programmaticProximityFormArray.removeAt(index);
                this.proximitySubmitButtonClicked.emit();
            }
        } else {
            // Find the index of the group with the given id
            const index = this.proximityFormArray.value.findIndex((item: ProximityTarget) => item.id === data.id);

            // If the item exists, remove it from the FormArray
            if (index !== -1) {
                this.proximityFormArray.removeAt(index);
            }
        }
    }
}