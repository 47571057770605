<form [formGroup]="form" class="container">
    <mat-checkbox color="primary" (change)="toggleBulkUpload($event)">{{ "TARGETING.GEOGRAPHY.BULK_UPLOAD_POSTAL_CODES"
        | translate }}</mat-checkbox>

    <div *ngIf="isBulkUploadEnabled" class="container-input">
        <div class="advocate-form-row__content">
            <advocate-ui-dropdown [form]="form" [controlArray]="postalCodeCountriesArray" [multiple]="true"
                [options]="postalCodeCountries$ | async"
                [title]="'TARGETING.SELECT_AN_OPTION' | translate"></advocate-ui-dropdown>
        </div>
        <div class="advocate-row-chips">
            <advocate-ui-chips [chips]="chips$ | async" [controlArray]="postalCodeCountriesArray" [withRemove]="true" />
        </div>
        <br>
        <ng-container *ngIf="postalCodeCountriesArray.controls.length > 0">
            <textarea formControlName="postalCodes" class="cux-textbox cux-textbox--stretched"
                [ngClass]="{'cux-textbox--error': errorMessage}"></textarea>
            <i>{{ "TARGETING.GEOGRAPHY.BULK_POSTAL_CODES_DESC" | translate }}</i>
            <div class="advocate-help-text" *ngIf="errorMessage">
                <p class="advocate-help-text--error">{{ errorMessage }}</p>
            </div>

            <div class="container-button">
                <advocate-ui-button [isCurved]="true" state="secondary" size="small"
                    [name]="'GEOFENCE.CLEAR_ALL' | translate" (onChange)="clearAll()"></advocate-ui-button>
                <advocate-ui-button [isCurved]="true" state="primary" size="small" [name]="'GENERAL.SUBMIT' | translate"
                    (onChange)="validatePostalCodes()">
                </advocate-ui-button>
                <mat-icon color="primary" *ngIf="validationSuccess" class="success-icon">check</mat-icon>
            </div>
        </ng-container>
    </div>
</form>