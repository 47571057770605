<div *ngIf="adsCreativeArray.length > 0" class="creative-table">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
        <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
            <th mat-header-cell *matHeaderCellDef>
                <ng-container *ngIf="column === 'headline_1'">{{ "CREATIVE.HEADLINE" | translate }} 1</ng-container>
                <ng-container *ngIf="column === 'headline_2'">{{ "CREATIVE.HEADLINE" | translate }} 2</ng-container>
                <ng-container *ngIf="column === 'headline_3'">{{ "CREATIVE.HEADLINE" | translate }} 3</ng-container>
                <ng-container *ngIf="column === 'edit'"></ng-container>
            </th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="column === 'edit'; else noDelete">
                    <advocate-icon (click)="removeCreative(element.id); $event.stopPropagation()" class="remove-icon"
                        size="extra-large" state="primary" name="delete"></advocate-icon>
                </ng-container>
                <ng-template #noDelete>{{element[column]}}</ng-template>
            </td>
        </ng-container>
        <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button aria-label="expand row"
                    (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                    <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                    <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
                <div class="example-element-detail"
                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="expanded-container">
                        <h2>{{ "CREATIVE.HEADLINE" | translate }}s</h2>
                        <p>{{ element.additional_headlines.join(', ') }}</p>
                    </div>
                    <div class="expanded-container">
                        <h2>{{ "CREATIVE.LANDING_PAGE_URL" | translate }}</h2>
                        <p>{{element.landing_url ?? ("GENERAL.NA" | translate) }}</p>
                    </div>
                    <div class="expanded-container">
                        <h2>{{ "GENERAL.DESCRIPTION" | translate}} 1</h2>
                        <p>{{element.description_1 ?? ("GENERAL.NA" | translate) }}</p>
                    </div>
                    <div class="expanded-container">
                        <h2>{{ "GENERAL.DESCRIPTION" | translate}} 2</h2>
                        <p>{{element.description_2 ?? ("GENERAL.NA" | translate) }}</p>
                    </div>
                    <div *ngFor="let description of element.additional_descriptions; let i = index"
                        class="expanded-container">
                        <h2>{{ "GENERAL.DESCRIPTION" | translate}} {{i + 3}}</h2>
                        <p>{{description}}</p>
                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="example-element-row"
            [class.example-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
</div>