<form [formGroup]="form">
    <div class="container">
        <div class="container_header">
            <span>{{ "GENERAL.BUDGET" | translate }}</span>
        </div>

        <div class="container_budget">
            <div class="container_budget--price">
                <div class="advocate-form">
                    <div class="advocate-form-row">
                        <div class="advocate-form-subrow">
                            <div class="advocate-form-subrow__col advocate-form-subrow__col--stretched">
                                <div class="advocate-form-subrow__label">
                                    <label for="daily-budget" class="advocate-form-label">{{ "BUDGET.DAILY_BUDGET" |
                                        translate }}
                                        ({{currencySignal()?.id}})
                                        <span class="advocate-required-field"></span>
                                        &nbsp;<advocate-ui-info-icon
                                            [infoText]="'BUDGET.DAILY_BUDGET_DES' | translate" />
                                    </label>
                                </div>
                                <div class="advocate-form-row__content">
                                    <input data-cy="daily_budget_search" type="number" id="daily-budget"
                                        autocomplete="off" formControlName="daily_budget"
                                        class="cux-textbox cux-textbox--stretched"
                                        [ngClass]="{'cux-textbox--error': ((form.get('daily_budget')?.errors?.['min'] || form.get('daily_budget')?.errors?.['required'] || form.get('daily_budget')?.errors?.['pattern'])
                                    && form.get('daily_budget')?.touched) || (searchBudgetErrorSignal() && searchBudgetErrorSignal()?.daily_budget?.[0])}" />
                                </div>
                                <div class="advocate-help-text"
                                    *ngIf="form.get('daily_budget')?.errors?.['min'] && form.get('daily_budget')?.touched">
                                    <p data-cy="daily-budget-min-err" class="advocate-help-text--error">{{
                                        "BUDGET.DAILY_BUDGET_MIN_CHAR" | translate
                                        }}</p>
                                </div>

                                <div class="advocate-help-text" *ngIf="form.get('daily_budget')?.errors?.['pattern']">
                                    <p data-cy="daily-budget-invalid-err" class="advocate-help-text--error">{{
                                        "STATUS.INVALID_NUMBER_FORMAT" | translate
                                        }}</p>
                                </div>

                                <div class="advocate-help-text"
                                    *ngIf="form.get('daily_budget')?.errors?.['required'] && form.get('daily_budget')?.touched">
                                    <p data-cy="daily-budget-req-err" class="advocate-help-text--error">{{
                                        "BUDGET.DAILY_BUDGET_NOT_EMPTY" | translate
                                        }}</p>
                                </div>
                                <div class="advocate-help-text"
                                    *ngIf="searchBudgetErrorSignal() && searchBudgetErrorSignal()?.daily_budget?.[0]">
                                    <p class="advocate-help-text--error">
                                        {{ searchBudgetErrorSignal()?.daily_budget![0] | ErrorTranslate }}
                                    </p>
                                </div>
                            </div>

                            <div class="advocate-form-subrow__col advocate-form-subrow__col--stretched">
                                <div class="advocate-form-subrow__label">
                                    <label for="cpc-bid" class="advocate-form-label">CPC {{ "CAMPAIGN.BID" | translate
                                        }} ({{currencySignal()?.id}})
                                        <span class="advocate-required-field"></span>
                                        &nbsp;<advocate-ui-info-icon [infoText]="'BUDGET.CPC_BID_DES' | translate" />
                                    </label>
                                </div>
                                <div class="advocate-form-row__content">
                                    <input data-cy="cpc_bid_search" type="number" id="cpc-bid" autocomplete="off"
                                        formControlName="cpc_bid" class="cux-textbox cux-textbox--stretched"
                                        [ngClass]="{'cux-textbox--error': ((form.get('cpc_bid')?.errors?.['min'] || form.get('cpc_bid')?.errors?.['required'])
                                        && form.get('cpc_bid')?.touched) || (searchBudgetErrorSignal() && searchBudgetErrorSignal()?.cpc_bid?.[0])}" />
                                </div>
                                <div class="advocate-help-text"
                                    *ngIf="form.get('cpc_bid')?.errors?.['min'] && form.get('cpc_bid')?.touched">
                                    <p data-cy="cpc-bid-min-err" class="advocate-help-text--error">{{
                                        "BUDGET.CPC_BID_MIN" | translate }}</p>
                                </div>
                                <div class="advocate-help-text"
                                    *ngIf="form.get('cpc_bid')?.errors?.['required'] && form.get('cpc_bid')?.touched">
                                    <p data-cy="cpc-bid-req-err" class="advocate-help-text--error">{{
                                        "BUDGET.CPC_BID_NOT_EMPTY" | translate }}
                                    </p>
                                </div>
                                <div class="advocate-help-text"
                                    *ngIf="searchBudgetErrorSignal() && searchBudgetErrorSignal()?.cpc_bid?.[0]">
                                    <p class="advocate-help-text--error">{{ searchBudgetErrorSignal()?.cpc_bid![0]
                                        | ErrorTranslate }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-3">
        <div class="container_header">
            <span>{{ "GENERAL.DATES" | translate }}</span>
        </div>

        <div class="container_dates">
            <div class="container_dates--row1">
                <div class="advocate-form">
                    <div class="advocate-form-row">
                        <div class="advocate-form-subrow">
                            <div class="advocate-form-subrow__col advocate-form-subrow__col--stretched">
                                <div class="advocate-form-subrow__label">
                                    <label class="advocate-form-label">{{ "GENERAL.START_DATE" | translate }}</label>
                                    <span class="advocate-required-field">&nbsp;</span>
                                </div>
                                <div class="advocate-form-row__content">
                                    <advocate-ui-datepicker data-cy="start_date_search" [form]="form"
                                        [control]="startDateControl" [label]="'GENERAL.START_DATE' | translate"
                                        [error]="searchBudgetErrorSignal() && searchBudgetErrorSignal()?.start_date?.[0] || (startDateControl.errors?.['required'] && startDateControl.touched)" />
                                </div>
                                <div class="advocate-help-text"
                                    *ngIf="searchBudgetErrorSignal() && searchBudgetErrorSignal()?.start_date?.[0]">
                                    <p class="advocate-help-text--error">{{
                                        searchBudgetErrorSignal()?.start_date![0] | ErrorTranslate }}
                                    </p>
                                </div>
                                <div class="advocate-help-text"
                                    *ngIf="startDateControl?.errors?.['required'] && startDateControl?.touched">
                                    <p class="advocate-help-text--error">{{ "BUDGET.START_DATE_NOT_EMPTY" | translate }}
                                    </p>
                                </div>
                            </div>

                            <div class="advocate-form-subrow__col advocate-form-subrow__col--stretched">
                                <div class="advocate-form-subrow__label">
                                    <label class="advocate-form-label">{{ "GENERAL.END_DATE" | translate }}</label>
                                    <span class="advocate-required-field">&nbsp;</span>
                                </div>
                                <div class="advocate-form-row__content">
                                    <advocate-ui-datepicker data-cy="end_date_search" [form]="form"
                                        [control]="endDateControl" [label]="'GENERAL.END_DATE' | translate"
                                        [error]="searchBudgetErrorSignal() && searchBudgetErrorSignal()?.end_date?.[0] || (endDateControl.errors?.['required'] && endDateControl.touched)" />
                                </div>
                                <div class="advocate-help-text"
                                    *ngIf="searchBudgetErrorSignal() && searchBudgetErrorSignal()?.end_date?.[0]">
                                    <p class="advocate-help-text--error">{{
                                        searchBudgetErrorSignal()?.end_date![0] | ErrorTranslate }}
                                    </p>
                                </div>
                                <div class="advocate-help-text"
                                    *ngIf="endDateControl?.errors?.['required'] && endDateControl?.touched">
                                    <p class="advocate-help-text--error">{{ "BUDGET.END_DATE_NOT_EMPTY" | translate }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div *ngIf="searchBudgetErrorSignal() && searchBudgetErrorSignal()?.generalError"
        class="stepper-error-disclaimer mt-3">
        <span> {{ searchBudgetErrorSignal()?.generalError}} </span>
    </div>
</form>