<div class="container--expansion-panel">
    <div class="expansion-panel">

        <advocate-ui-expansion-panel [isExpanded]="true" [title]="'TARGETING.GEOGRAPHY.TITLE' | translate"
            collapsedHeight="6.4rem">
            <ng-container renderComponent>
                <advocate-ui-geography-search [targetError]="targetErrorSignal()"
                    [proximityUnitType]="proximityUnitTypes$ | async" />
            </ng-container>
        </advocate-ui-expansion-panel>
    </div>

    <div class="expansion-panel" data-cy="devicesPanel_search">
        <advocate-ui-expansion-panel [title]="'TARGETING.DEVICES_LANGUAGES' | translate" [optional]="true"
            collapsedHeight="6.4rem">
            <ng-container renderComponent>
                <advocate-ui-device-language [devices]="devices$ | async"
                    [languages]="languages$ | async"></advocate-ui-device-language>
            </ng-container>
        </advocate-ui-expansion-panel>
    </div>
</div>

<div class="mt-3 container container--grid_items">
    <div class="container--grid_items--button">
        <h2>{{ "TARGETING.NEED_MANAGED_SERVICE" | translate }}</h2>
        <span>{{ "TARGETING.NEED_HELP" | translate }}</span>
    </div>
    <div class="container--grid_items--button container--grid_items--button--right">
        <advocate-icon [isSVG]="true" svgName="talktomanager-icon.svg"></advocate-icon>
        <advocate-ui-button [isCurved]="true" state="secondary" size="extra-large"
            [name]="'TARGETING.TALK_CAMPAIGN_MANAGER' | translate" (click)="openContactModel()"></advocate-ui-button>
    </div>
</div>