import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { GtmService } from '../services/gtm.service';
import { UtilityService } from '../services/utility.service';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'advocate-ui-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private subs = new Subscription();
  loggedIn = true;
  authInfo$ = this.authService.loginInfo();

  constructor(private authService: AuthService, private utility: UtilityService, private translate: TranslateService, private gtm: GtmService) { }

  ngOnInit(): void {
    this.translate.setDefaultLang('en_US');
    this.gtm.loadGtm();
    this.subs.add(this.authInfo$.subscribe());
    this.subs.add(this.utility.getNomenClatures().subscribe());
    if (environment.production) {
      console.log("We are running in production mode");
      console.log(`API Key: ${environment.apiKey}`);
    } else {
      console.log("We are running in development mode");
      console.log(`API Key: ${environment.apiKey}`);
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
