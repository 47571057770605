<ng-container *ngIf="{form: (form$ | async)} as vm">
    <div class="container">
        <div class="container_header">
            <span>{{ "SUMMARY.CAMPAIGN_SUMMARY" | translate }}</span>
            <advocate-icon [isSVG]="true" svgName="edit.svg" (click)="changeStepper(1)"></advocate-icon>
        </div>

        <div class="container_campaign-summary">
            <span>{{ "GENERAL.BALANCE" | translate }}</span>
            <span>{{currencySignal()?.name}} {{vm.form?.searchBudgetGroup?.daily_budget}}</span>
        </div>
    </div>

    <div class="container mt-3">
        <div class="container_header">
            <span>{{ "GENERAL.GENERAL" | translate }}</span>
            <advocate-icon [isSVG]="true" svgName="edit.svg" (click)="changeStepper(1)"></advocate-icon>
        </div>

        <div class="container_body">
            <mat-divider></mat-divider>
            <div class="container_item">
                <div class="container_item-header"><span>{{ "GENERAL.ADVERTISER" | translate }}</span></div>
                <div class="container_item-chips">
                    <div data-cy="sum-advertiserName-search" class="container_item-chips-chip"><advocate-ui-chips
                            [disabled]="true"
                            [chipName]="vm.form?.campaignNameForm?.selectedAdvertiser?.name ?? ('GENERAL.NA' | translate)" />
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="container_item">
                <div class="container_item-header"> <span>{{ "GENERAL.NAME" | translate }}</span></div>
                <div class="container_item-chips">
                    <div data-cy="sum-campaignName-search" class="container_item-chips-chip"><advocate-ui-chips
                            [disabled]="true"
                            [chipName]="vm.form?.campaignNameForm.campaignName ?? ('GENERAL.NA' | translate)" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-3">
        <div class="container_header">
            <span>{{ "CAMPAIGN.BUDGET_DATES" | translate }}</span>
            <advocate-icon [isSVG]="true" svgName="edit.svg" (click)="changeStepper(3)"></advocate-icon>
        </div>

        <div class="container_body">
            <mat-list>
                <mat-divider></mat-divider>
                <div class="container_item">
                    <div class="container_item-header"><span>{{ "BUDGET.DAILY_BUDGET" | translate }}</span></div>
                    <div class="container_item-chips">
                        <div data-cy="daily-budget-search" class="container_item-chips-chip"><advocate-ui-chips
                                [disabled]="true"
                                [chipName]="vm.form?.searchBudgetGroup.daily_budget ?? ('GENERAL.NA' | translate)" />
                        </div>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="container_item">
                    <div class="container_item-header"><span>{{ "GENERAL.DATES" | translate }}</span></div>
                    <div class="container_item-chips">
                        <div data-cy="startDate-search" class="container_item-chips-chip"><advocate-ui-chips
                                [disabled]="true"
                                [chipName]="vm.form?.searchBudgetGroup.start_date | date: 'dd/MM/yyyy'" />
                        </div>
                        <div class="container_item-chips-chip"><span>to</span></div>
                        <div data-cy="endDate-search" class="container_item-chips-chip"><advocate-ui-chips
                                [disabled]="true"
                                [chipName]="vm.form?.searchBudgetGroup.end_date | date: 'dd/MM/yyyy'" />
                        </div>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="container_item">
                    <div class="container_item-header"> <span>{{ "BUDGET.CPC_BID" | translate }}</span></div>
                    <div class="container_item-chips">
                        <div data-cy="cpcBid-search" class="container_item-chips-chip"><advocate-ui-chips
                                [disabled]="true"
                                [chipName]="vm.form?.searchBudgetGroup.cpc_bid ?? ('GENERAL.NA' | translate)" />
                        </div>
                    </div>
                </div>
            </mat-list>
        </div>
    </div>

    <div class="container mt-3">
        <div class="container_header">
            <span>{{ "GENERAL.KEYWORDS" | translate }}</span>
            <advocate-icon [isSVG]="true" svgName="edit.svg" (click)="changeStepper(4)"></advocate-icon>
        </div>

        <div class="container_body">
            <mat-list>
                <mat-divider></mat-divider>
                <div class="container_item">
                    <div class="container_item-header"><span>{{ "CAMPAIGN.TARGETING_KEYWORDS" | translate }}</span>
                    </div>
                    <div class="container_item-chips">
                        <div data-cy="positiveKeyword-search" class="container_item-chips-chip">
                            <ng-container
                                *ngIf="vm.form?.keywordFormGroup.targetingFormArray?.length > 0; else noElement">
                                <advocate-ui-chips *ngFor="let keyword of vm.form?.keywordFormGroup.targetingFormArray"
                                    [disabled]="true" [chipName]="keyword.keyword" />
                            </ng-container>
                            <ng-template #noElement>
                                <advocate-ui-chips [disabled]="true" [chipName]="'GENERAL.NA' | translate" />
                            </ng-template>
                        </div>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="container_item">
                    <div class="container_item-header"><span>{{ "CAMPAIGN.NEGATIVE_KEYWORDS" | translate }}</span></div>
                    <div class="container_item-chips">
                        <div data-cy="negativeKeyword-search" class="container_item-chips-chip">
                            <ng-container
                                *ngIf="vm.form?.keywordFormGroup.negativeFormArray?.length > 0; else noElement">
                                <advocate-ui-chips *ngFor="let keyword of vm.form?.keywordFormGroup.negativeFormArray"
                                    [disabled]="true" [chipName]="keyword.keyword" />
                            </ng-container>
                            <ng-template #noElement>
                                <advocate-ui-chips [disabled]="true" [chipName]="'GENERAL.NA' | translate" />
                            </ng-template>
                        </div>
                    </div>
                </div>

            </mat-list>
        </div>
    </div>

    <div class="container mt-3">
        <div class="container_header">
            <span>{{'CAMPAIGN.TARGETING' | translate}}</span>
            <advocate-icon [isSVG]="true" svgName="edit.svg" (click)="changeStepper(5)"></advocate-icon>
        </div>

        <div class="container_body">
            <mat-list>
                <mat-divider></mat-divider>
                <div class="container_item">
                    <div class="container_item-header"><span>{{ "CAMPAIGN.LOCATIONS" | translate }}</span></div>
                    <div class="container_item-chips">
                        <div data-cy="sum-locations-search" class="container_item-chips-chip">
                            <ng-container *ngIf="vm.form?.targetingFormGroup?.locations?.length > 0; else noElement">
                                <advocate-ui-chips *ngFor="let location of vm.form?.targetingFormGroup?.locations"
                                    [disabled]="true" [chipName]="location.name" />
                            </ng-container>
                            <ng-template #noElement>
                                <advocate-ui-chips [disabled]="true" [chipName]="'GENERAL.NA' | translate" />
                            </ng-template>
                        </div>
                    </div>
                </div>

                <mat-divider></mat-divider>
                <div class="container_item">
                    <div class="container_item-header"><span>{{ "TARGETING.DEVICES" | translate }}</span></div>
                    <div class="container_item-chips">
                        <div data-cy="sum-devices-search" class="container_item-chips-chip">
                            <ng-container *ngIf="vm.form?.targetingFormGroup?.devices?.length > 0; else noElement">
                                <advocate-ui-chips *ngFor="let device of vm.form?.targetingFormGroup?.devices"
                                    [disabled]="true" [chipName]="device.name" />
                            </ng-container>
                            <ng-template #noElement>
                                <advocate-ui-chips [disabled]="true" [chipName]="'GENERAL.NA' | translate" />
                            </ng-template>
                        </div>
                    </div>
                </div>

                <mat-divider></mat-divider>
                <div class="container_item">
                    <div class="container_item-header"><span>{{ "TARGETING.LANGUAGES" | translate }}</span></div>
                    <div class="container_item-chips">
                        <div data-cy="sum-languages-search" class="container_item-chips-chip">
                            <ng-container *ngIf="vm.form?.targetingFormGroup?.languages?.length > 0; else noElement">
                                <advocate-ui-chips *ngFor="let language of vm.form?.targetingFormGroup?.languages"
                                    [disabled]="true" [chipName]="language.name" />
                            </ng-container>
                            <ng-template #noElement>
                                <advocate-ui-chips [disabled]="true" [chipName]="'GENERAL.NA' | translate" />
                            </ng-template>
                        </div>
                    </div>
                </div>
            </mat-list>
        </div>
    </div>

    <div *ngFor="let error of publishErrorSignal()" class="stepper-error-disclaimer mt-3">
        <span> {{ error | ErrorTranslate }} </span>
    </div>
</ng-container>