import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../../../shared/button/button.component';
import { IconComponent } from '../../../../../shared/icon/icon.component';

@Component({
  selector: 'advocate-ui-budget-overspend-error-dialog',
  templateUrl: './budget-overspend-error-dialog.component.html',
  styleUrls: ['./budget-overspend-error-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule, IconComponent, ButtonComponent, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BudgetOverspendErrorDialogComponent {
  constructor(public dialogRef: MatDialogRef<BudgetOverspendErrorDialogComponent>, private router: Router) { }

  onNoClick(redirect: boolean): void {
    if(redirect) this.router.navigate(['/home/payment'], { state: { redirect: true } });
    this.dialogRef.close();
  }

}
