<form [formGroup]="form">
    <mat-form-field appearance="fill" class="ngx-dropdown no-border" [class.invalid]="invalid"
        [class.ngx-border]="border" [class.ngx-white]="isWhite">
        <mat-label *ngIf="showLabel" [ngStyle]="{'color': invalid ? 'red' : '' }">{{title}} <span
                *ngIf="required">*</span></mat-label>

        <!-- MULTIPLE SELECTION -->
        <mat-select [formControl]="selectFormControl" *ngIf="multiple && !paginated"
            (selectionChange)="selectionChange($event)" disableOptionCentering>
            <mat-select-trigger>
            </mat-select-trigger>
            <mat-option>
                <ng-container *ngIf="!isLive; else live">
                    <ngx-mat-select-search name="search-filter-select" ngDefaultControl [placeholderLabel]="'Search'"
                        [formControl]="searchFilterFormControll"
                        noEntriesFoundLabel="No result found"></ngx-mat-select-search>
                </ng-container>

                <!--  *ngIf="!isLive" -->
                <ng-template #live>
                    <ngx-mat-select-search name="search-filter-select" ngDefaultControl [placeholderLabel]="'Search'"
                        [formControl]="liveSearchControl" noEntriesFoundLabel="No result found"></ngx-mat-select-search>
                </ng-template>
            </mat-option>
            <mat-option *ngFor="let option of options" [value]="option">
                <div class="option title">{{option.name}}</div>
            </mat-option>
        </mat-select>

        <!-- Paginated Selection -->
        <mat-select *ngIf="multiple && paginated" (selectionChange)="onSelectionChange($event)" [formControl]="
            selectFormControl" [compareWith]="compareById" multiple>
            <mat-option>
                <ngx-mat-select-search name="search-filter-select" ngDefaultControl [placeholderLabel]="'Search'"
                    [formControl]="liveSearchControl" noEntriesFoundLabel="No result found"></ngx-mat-select-search>
            </mat-option>
            <mat-divider></mat-divider>
            @for (option of options; track option) {
            <mat-option [value]="option">
                <div class="option title">{{option.name}}</div>
            </mat-option>
            }
        </mat-select>

        <!-- SINGLE SELECTION -->
        <mat-select *ngIf="!multiple" [formControl]="control" disableOptionCentering
            (selectionChange)="selectionChange($event)" [panelClass]="panelClass">
            <mat-select-trigger>
                <span class="selected-content">{{ control.value?.name }}</span> &nbsp;
                <mat-icon color="primary" *ngIf="showIcon"> {{ icon }} </mat-icon>
            </mat-select-trigger>
            <ng-container *ngIf="showSearch">
                <mat-option>
                    <ngx-mat-select-search name="search-filter-select" ngDefaultControl
                        [placeholderLabel]="'GENERAL.SEARCH' | translate" [formControl]="searchFilterFormControll"
                        noEntriesFoundLabel="No result found"></ngx-mat-select-search>
                </mat-option>
            </ng-container>
            <mat-option *ngFor="let option of options" [value]="option">
                <div class="option title">{{option.name}}</div>
            </mat-option>
        </mat-select>

        <span matTextSuffix *ngIf="hasInternalReset && !disabled">
            <mat-icon (click)="doInternalResetFilter($event)" class="close-icon material-icons-outlined">close
            </mat-icon>
        </span>
        <mat-hint [ngStyle]="{'color': invalid ? 'red' : '' }" *ngIf="hint">{{hint}}</mat-hint>
    </mat-form-field>
</form>