import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteGuard } from '../services/route-guard.service';
import { ImpersonateAdminComponent } from './admin/impersonate-app.component';
import { LoginGuard } from './auth/login-guard';
import { LoginComponent } from './auth/login/login.component';
import { ResetPasswordGuard } from './auth/reset-password.guard';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { SearchCampaignStepperComponent } from './campaign/stepper/search/search-campaign-stepper.component';
import { LandingPageComponent } from './home/landing-page.component';

export const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'admin/impersonate/:userId', component: ImpersonateAdminComponent },
  {
    path: 'user/reset-password',
    component: ResetPasswordComponent,
    canActivate: [ResetPasswordGuard]  // Guard to check for token
  },
  {
    path: 'home',
    loadComponent: () => import('./home/home.component').then(h => h.HomeComponent),
    children: [
      {
        path: '',
        component: LandingPageComponent,
      },
      {
        path: 'campaign',
        loadComponent: () => import('./campaign/new-campaign.component').then(n => n.NewCampaignComponent),
      },
      {
        path: 'advanced-reporting',
        loadComponent: () => import('./advanced-reporting/advanced-reporting.component').then(a => a.AdvancedReportingComponent),
        children: [
          {
            path: 'instant-report',
            loadComponent: () => import('./advanced-reporting/instant-report/instant-report.component').then(m => m.InstantReportComponent)
          },
          {
            path: 'locations',
            loadComponent: () => import('./advanced-reporting/locations/locations.component').then(m => m.AdvancedReportingLocationsComponent)
          },
          {
            path: 'creative-reporting',
            loadComponent: () => import('./advanced-reporting/creative-reporting/creative-reporting.component').then(m => m.AdvancedReportingCreativeReportingComponent)
          },
          {
            path: 'domains',
            loadComponent: () => import('./advanced-reporting/domains/domains.component').then(m => m.AdvancedReportingDomainsComponent)
          },
          {
            path: 'reach-frequency',
            loadComponent: () => import('./advanced-reporting/reach-frequency/reach-frequency.component').then(m => m.AdvancedReportingReachFrequencyComponent)
          },
          {
            path: 'metrics-guide',
            loadComponent: () => import('./advanced-reporting/metrics/metrics.component').then(m => m.AdvancedReportingMetricsComponent)
          },
          {
            path: 'keywords',
            loadComponent: () => import('./advanced-reporting/keywords/keywords.component').then(m => m.AdvancedReportingKeywordsComponent)
          },
          {
            path: '',
            redirectTo: 'instant-report',
            pathMatch: 'full',
          },
        ]
      },
      {
        path: 'programmatic/creative-options',
        loadComponent: () => import('./campaign/programmatic-creative-options/programmatic-creative-options.component').then(p => p.ProgrammaticCreativeOptionsComponent),
      },
      {
        path: 'programmatic',
        loadComponent: () => import('./campaign/stepper/pragrammatic/programmatic-campaign-stepper.component').then(p => p.ProgrammaticCampaignStepperComponent),
        canActivate: [RouteGuard]
      },
      {
        path: 'search/:id',
        component: SearchCampaignStepperComponent,
        canActivate: [RouteGuard],
        children: [
          {
            path: 'step1',
            component: SearchCampaignStepperComponent
          },
          {
            path: 'step2',
            component: SearchCampaignStepperComponent
          },
          {
            path: 'step3',
            component: SearchCampaignStepperComponent
          },
          {
            path: 'step4',
            component: SearchCampaignStepperComponent
          },
          {
            path: 'step5',
            component: SearchCampaignStepperComponent
          },
          {
            path: 'step6',
            component: SearchCampaignStepperComponent
          },
          {
            path: '',  // Default route for search, redirects to step1
            redirectTo: 'step1',
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'youtube',
        loadComponent: () => import('./campaign/stepper/youtube/youtube-campaign-stepper.component').then(y => y.YoutubeCampaignStepperComponent),
        canActivate: [RouteGuard]
      },
      {
        path: 'reports',
        children: [
          {
            path: '',
            loadComponent: () => import('./reports/reports-home.component').then(h => h.ReportsHomeComponent),
            pathMatch: 'full'
          },
          {
            path: 'campaign-info/:id',
            loadComponent: () => import('./reports/campaign-table-info/campaign-table-info.component').then(c => c.CampaignTableInfoComponent),
          }
        ]
      },
      {
        path: 'organisation',
        loadComponent: () => import('./organisation/organisation-groups.component').then(o => o.OrganisationGroupsComponent),
      },
      {
        path: 'campaign_group_view',
        loadComponent: () => import('./organisation/campaign-group-view.component').then(c => c.CampaignGroupViewComponent),
      },
      {
        path: 'audience',
        loadComponent: () => import('./audience/audience.component').then(a => a.AudienceComponent),
      },
      {
        path: 'payment',
        loadComponent: () => import('./payment/payment-home.component').then(p => p.PaymentHomeComponent)
      },
      {
        path: 'verification',
        loadComponent: () => import('./verification/verification.component').then(v => v.VerificationComponent)
      },
      {
        path: 'help',
        children: [
          {
            path: '',
            loadComponent: () => import('./help-center/help-center.component').then(v => v.HelpCenterComponent),
          },
          {
            path: 'faq',
            loadComponent: () => import('./help-center/faqs/faqs.component').then(f => f.FaqsComponent),
          }
        ]
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
