import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatChipsModule } from '@angular/material/chips';
import { MatDivider } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { TranslateModule } from "@ngx-translate/core";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { Subscription } from "rxjs";
import { AdvocateOption } from "../../models";
import { GetTruncatedLabelPipe } from "../pipes/get-label.pipe";
import { IsSelectedPipe } from "../pipes/is-selected.pipe";

const MaterialModules = [
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    MatSelectModule,
    MatChipsModule,
    MatIconModule,
    TranslateModule,
    MatDivider
];

@Component({
    selector: 'advocate-ui-dropdown',
    standalone: true,
    imports: [CommonModule, IsSelectedPipe, GetTruncatedLabelPipe, FormsModule, ReactiveFormsModule, ...MaterialModules],
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DropDownComponent implements OnInit, OnChanges, OnDestroy {
    @Input() options: AdvocateOption[] | undefined | null = [];
    @Input() selected!: AdvocateOption[] | AdvocateOption | undefined | null; // used to load preselected options (captured in ngOnInit)

    @Input() showSearch = true;
    @Input() title = 'Options';
    @Input() multiple = false;
    @Input() required = false;
    @Input() showLabel = true;
    @Input() disabled = false;
    @Input() hasInternalReset = false;
    @Input() border = false;
    @Input() invalid = false;
    @Input() hint = '';
    @Input() isWhite: boolean = false;
    @Input() isLive = false;
    @Input() showIcon = false;
    @Input() icon?: string;
    @Input() paginated = true;

    @Input() form: FormGroup = new FormGroup({});
    @Input() control: FormControl = new FormControl;
    @Input() liveSearchControl: FormControl = new FormControl;
    @Input() controlArray: FormArray;
    @Input() panelClass: string = '';

    @Output() onChange = new EventEmitter<AdvocateOption[] | AdvocateOption>();
    @Output() selectedValueChange: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();

    /** control for search input in multiple mode */
    protected searchFilterFormControll = new FormControl('');
    private sub: Subscription = new Subscription();
    protected optionsCopy!: AdvocateOption[];
    selectFormControl = new FormControl();


    constructor(private fb: FormBuilder) {
        this.controlArray = this.fb.array([]);
    }

    ngOnInit(): void {
        // listen for search field value changes
        this.sub.add(this.searchFilterFormControll.valueChanges
            .subscribe((searchText: any) => {
                this.filterOptions(searchText);
            }));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (Object.prototype.hasOwnProperty.call(changes, "options") && !changes['options'].firstChange && changes['options'].currentValue) {
            const tmpOptions = changes['options'].currentValue;
            this.optionsCopy = [...tmpOptions];
        }

        if (this.selected && this.multiple === false) {
            this.control.setValue(this.selected);
        }

        if (changes['controlArray']?.currentValue) {
            // this.selectFormControl = this.controlArray.value?.[0];
            this.sub.add(this.controlArray.valueChanges.subscribe(m => {
                this.selectFormControl = new FormControl(m)
            }))
        }
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    filterOptions(searchText: any): void {
        if (!this.optionsCopy && this.options) this.optionsCopy = [...this.options];
        this.options = this.optionsCopy?.filter((option: any) => {
            const show = option.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
            return show;
        });
    }

    protected doInternalResetFilter(event: any): void {
        event.stopPropagation();
        this.control.reset();
        this.clear();
    }

    public clear(): void {
        this.control.reset();
    }

    selectionChange(event: any) {
        this.selectFormControl.reset();
        if (event) {
            // Check if the element.id is already present in the array.
            const isExisting = this.controlArray.controls.some(
                (control: AbstractControl) => control.value.id === event.value.id
            );

            // Only add the new FormControl if it doesn't exist.
            if (!isExisting) {
                this.controlArray.push(new FormControl(event.value));
            }
        } else {
            // Find the index of the control that needs to be removed.
            const index = this.controlArray.controls.findIndex(
                (control: AbstractControl) => control.value.id === event.value.id
            );

            // If found, remove the control at the found index.
            if (index !== -1) {
                this.controlArray.removeAt(index);
            }
        }

        // Emit the change event.
        this.selectedValueChange.emit(event);
    }

    onSelectionChange(event: MatSelectChange) {
        const selectedItems: AdvocateOption[] = event.value || [];

        // Clear the FormArray
        this.controlArray.clear();

        // Add each item
        selectedItems.forEach(item => {
            this.controlArray.push(new FormControl(item));
        });

        // Mark as updated
        this.controlArray.updateValueAndValidity();

        // ** Also set it on selectFormControl so the UI sees them as selected **
        this.selectFormControl.setValue(selectedItems);

        this.selectedValueChange.emit(event);
    }

    toggleOption(option: AdvocateOption) {
        const index = this.controlArray.controls.findIndex(c => c.value.id === option.id);
        if (index > -1) {
            // remove from array
            this.controlArray.removeAt(index);
        } else {
            // add to array
            this.controlArray.push(new FormControl(option));
        }
    }

    isInFormArray(option: AdvocateOption): boolean {
        return this.controlArray.controls.some(c => c.value.id === option.id);
    }

    compareById(obj1: { id: string }, obj2: { id: string }): boolean {
        return obj1 && obj2 && obj1.id === obj2.id;
    }

}