import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit, WritableSignal } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, map, take } from 'rxjs';
import { AdvocateOption, CampaignNoMenClature, ChipsModel, SearchCampaignGetReponseData, SearchKeywordErrorInterface, keywordItems } from '../../../models';
import { FormService } from '../../../services/form.service';
import { ButtonComponent } from '../../../shared/button/button.component';
import { ChipsComponent } from '../../../shared/chips/chips.component';
import { DisclaimerComponent } from '../../../shared/disclaimer/disclaimer.component';
import { DropDownComponent } from '../../../shared/dropdown/dropdown.component';
import { IconComponent } from '../../../shared/icon/icon.component';
import { InfoIconComponent } from '../../../shared/info-icon/info-icon.component';
import { BackendErrorTranslate } from '../../../shared/pipes/backend-error-i18n.pipe';
import { ErrorHandlingViewModelService } from '../../view-model/error-handling-vm.service';
import { UtilityViewModelService } from '../../view-model/utility-vm.service';

@Component({
    selector: 'advocate-ui-keywords-page',
    templateUrl: './keywords-page.component.html',
    styleUrls: ['./keywords-page.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        ButtonComponent,
        DropDownComponent,
        IconComponent,
        MatTooltipModule,
        DisclaimerComponent,
        ChipsComponent,
        MatChipsModule,
        MatIconModule,
        ReactiveFormsModule,
        InfoIconComponent,
        TranslateModule,
        BackendErrorTranslate
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeywordsPageComponent implements OnInit {
    searchKeywordErrorSignal: WritableSignal<SearchKeywordErrorInterface | null> = this.errorService.searchKeywordErrorSignal;
    targetingKeywordFormGroup: FormGroup = this.formService.targetingKeywordFormGroup;
    negativeKeywordFormGroup: FormGroup = this.formService.negativeKeywordFormGroup;

    targetingFormArray: FormArray = this.formService.targetingFormArray;
    negativeFormArray: FormArray = this.formService.negativeFormArray;

    get targetingMatchType(): FormControl {
        return this.targetingKeywordFormGroup.get('match_type_label') as FormControl;
    }

    get negativeMatchType(): FormControl {
        return this.negativeKeywordFormGroup.get('match_type_label') as FormControl;
    }

    keyWordType$: Observable<AdvocateOption[] | null> = this.utilityService.getCampaignNoMenClatureEffect().pipe(
        map((data: CampaignNoMenClature | null) => {
            if (!data) return null;
            return data.keyword_match_type_id
        })
    );

    keywordschips: ChipsModel[] = [];
    dateschips: ChipsModel[] = [];

    constructor(private fb: FormBuilder, private utilityService: UtilityViewModelService, private formService: FormService, private errorService: ErrorHandlingViewModelService, private activatedRoute: ActivatedRoute, private router: Router) { }

    ngOnInit(): void {
        const id = this.activatedRoute.snapshot.paramMap.get('id');

        if (id && this.router.url.includes('step4')) {
            this.utilityService.getSearchCampaignStepsEffect(+id).pipe(take(1)).subscribe({
                next: (res: SearchCampaignGetReponseData) => {
                    this.formService.negativeFormArray.clear();
                    this.formService.targetingFormArray.clear();

                    res.campaign.step4.negative_keywords?.forEach((item: keywordItems) => {
                        this.formService.negativeKeywordFormGroup.setValue({
                            keyword: item.keyword,
                            match_type_label: item.match_type_label,
                            active: item.active,
                            id: item.id,
                            match_type_id: item.match_type_id,
                            type_id: item.type_id,
                            type_label: item.type_label
                        });
                        this.formService.negativeFormArray.push(this.formService.cloneFormGroup(this.formService.negativeKeywordFormGroup));
                        this.formService.negativeKeywordFormGroup.reset();
                    });

                    res.campaign.step4.keywords?.forEach(item => {
                        this.formService.targetingKeywordFormGroup.setValue({
                            keyword: item.keyword,
                            match_type_label: item.match_type_label,
                            active: item.active,
                            id: item.id,
                            match_type_id: item.match_type_id,
                            type_id: item.type_id,
                            type_label: item.type_label

                        });
                        this.formService.targetingFormArray.push(this.formService.cloneFormGroup(this.formService.targetingKeywordFormGroup));
                        this.formService.targetingKeywordFormGroup.reset();
                    });
                },
                error: _ => this.router.navigate(['/home'])
            })
        }
    }

    onAddKeyword(): void {
        this.utilityService.addKeywordsEffect().subscribe({
            next: v => {
                this.errorService.searchKeywordErrorSignal.set(null);
                const formGroup: FormGroup = this.fb.group({
                    active: v.keyword.active,
                    id: v.keyword.id,
                    match_type_id: v.keyword.match_type_id,
                    match_type_label: v.keyword.match_type_label,
                    type_id: v.keyword.type_id,
                    type_label: v.keyword.type_label,
                    keyword: this.targetingKeywordFormGroup.get('keyword')?.value
                })
                this.formService.targetingFormArray.push(formGroup);
                this.targetingKeywordFormGroup.reset();
            },
            error: (error: HttpErrorResponse) => {
                console.error('Search Keyword Error:', error);
                if (error.status !== 422 || !error.error) {
                    this.errorService.searchKeywordErrorSignal.set({ generalError: `${error.status}: ${error.statusText}` });
                    return;
                }
                const display: SearchKeywordErrorInterface = error.error;
                this.errorService.searchKeywordErrorSignal.set(display);
            }
        });
    }

    onAddKNegativeKeyword(): void {
        this.utilityService.addNegativeKeywordsEffect().subscribe({
            next: v => {
                this.errorService.searchKeywordErrorSignal.set(null);
                const formGroup: FormGroup = this.fb.group({
                    active: v.keyword.active,
                    id: v.keyword.id,
                    match_type_id: v.keyword.match_type_id,
                    match_type_label: v.keyword.match_type_label,
                    type_id: v.keyword.type_id,
                    type_label: v.keyword.type_label,
                    keyword: this.negativeKeywordFormGroup.get('keyword')?.value
                })
                this.formService.negativeFormArray.push(formGroup);
                this.negativeKeywordFormGroup.reset();
            },
            error: (error: HttpErrorResponse) => {
                console.error('Search Keyword Error:', error);
                if (error.status !== 422 || !error.error) {
                    this.errorService.searchKeywordErrorSignal.set({ generalError: `${error.status}: ${error.statusText}` });
                    return;
                }
                const display: SearchKeywordErrorInterface = error.error;
                this.errorService.searchKeywordErrorSignal.set({ negative_keyword: display.keyword });
            }
        });
    }

    removeTargetingKeyword(chip: keywordItems): void {
        const index = this.targetingFormArray.controls.findIndex(
            (control) => control.value.id === chip.id
        );

        if (index !== -1) {
            this.targetingFormArray.removeAt(index);
        }
    }

    removeNegativeKeyword(chip: keywordItems): void {
        const index = this.negativeFormArray.controls.findIndex(
            (control) => control.value.id === chip.id
        );

        if (index !== -1) {
            this.negativeFormArray.removeAt(index);
        }
    }
}