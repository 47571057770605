import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AdvocateOption, ProximityErrorResponse } from '../../../../models';
import { FormService } from '../../../../services/form.service';
import { PrefillCampaignsService } from '../../../../services/prefillCampaign.service';
import { ButtonComponent } from '../../../../shared/button/button.component';
import { ChipsComponent } from '../../../../shared/chips/chips.component';
import { DropDownComponent } from '../../../../shared/dropdown/dropdown.component';
import { IconComponent } from '../../../../shared/icon/icon.component';
import { BackendErrorTranslate } from '../../../../shared/pipes/backend-error-i18n.pipe';
import { SliderComponent } from '../../../../shared/slider/slider.component';
import { ProximityTableComponent } from '../../../../shared/tables/proximity/proximity-table.component';
import { ErrorHandlingViewModelService } from '../../../view-model/error-handling-vm.service';
import { UtilityViewModelService } from '../../../view-model/utility-vm.service';

@Component({
    selector: 'advocate-ui-proximity',
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule, SliderComponent, IconComponent, MatTooltipModule, ChipsComponent, ButtonComponent, DropDownComponent, ProximityTableComponent, TranslateModule, BackendErrorTranslate],
    templateUrl: './proximity.component.html',
    styleUrls: ['./proximity.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProximityComponent implements OnDestroy {
    @Input() form: FormGroup = new FormGroup({});
    @Input() proximityUnitType!: AdvocateOption[] | null;
    @Input() proximityError!: ProximityErrorResponse | null;

    private subs = new Subscription();
    protected proximityFormGroup: FormGroup = this.formService.proximityFormGroup;

    get proximityUnitTypeControl(): FormControl {
        return this.formService.proximityFormGroup.get('radius_unit_id') as FormControl;
    }

    get proximityValueControl(): FormControl {
        return this.formService.proximityFormGroup.get('radius') as FormControl;
    }

    constructor(private formService: FormService, private errorService: ErrorHandlingViewModelService, private utilityService: UtilityViewModelService,
        private prefillCampaignService: PrefillCampaignsService) { }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    clearLatLong(): void {
        this.proximityFormGroup.get('latitude')?.reset();
        this.proximityFormGroup.get('longitude')?.reset();
    }

    addProximityTarget(): void {
        this.subs.add(this.utilityService.createProximityEffect().subscribe({
            next: () => {
                this.errorService.setProximityError(null);
                const blob = this.prefillCampaignService.generateCSV(this.formService.proximityFormArray.value);
                this.formService.targetingFormGroup.get('geoFenceFile')?.patchValue({ url: window.URL.createObjectURL(blob), name: 'locations.csv' });
            },
            error: (error: HttpErrorResponse) => {
                console.error('Proximity Error value', error);
                if (error.status === 500) {
                    this.errorService.setProximityError({ message: error.error.message });
                    return;
                }
                this.errorService.setProximityError(error.error)
            }
        }))
    }
}