<div class="container">
  <h2>{{"CAMPAIGN.NAME_YOUR_CAMPAIGN" | translate}}</h2>
  <form [formGroup]="campaignNameFormGroup" class="advocate-form">
    <div class="advocate-form-row">
      <div class="advocate-form-row__label advocate-form-label">
        <label for="dropdown" class="advocate-form-label__content">{{"CAMPAIGN.WHO_RUNS_CAMPAIGN" | translate}}</label>
        <span class="advocate-required-field"></span>
        &nbsp;<advocate-ui-info-icon [infoText]="campaignRunningHoverText" [showMatTooltip]="false"
          [translateYPercentage]="-20" />
      </div>

      <div class="advocate-form-row__content">
        <advocate-ui-dropdown data-cy="campaign-advertiserName" [options]="advertisersNames$ | async"
          [form]="campaignNameFormGroup" [control]="selectedAdvertiserControl" [title]="'GENERAL.SELECT_AN_OPTION' |
          translate"></advocate-ui-dropdown>
      </div>
    </div>

    <ng-container *ngIf="isAddNewAdvertiser">
      <div class="advocate-form-row">
        <div class="advocate-form-row__label advocate-form-label">
          <label for="name-advertiser" class="advocate-form-label__content">{{'CAMPAIGN.ADD_ADVERTISER' |
            translate}}</label>
        </div>

        <div class="advocate-form-row__content">
          <input data-cy="advertiserName-input" type="text" id="name-advertiser" formControlName="advertiserName"
            autocomplete="off" class="cux-textbox cux-textbox--stretched"
            [ngClass]="{'cux-textbox--error': errorSignal() && errorSignal()?.name?.[0]}" />
        </div>
        <div class="advocate-help-text" *ngIf="errorSignal() && errorSignal()?.name?.[0]">
          <p class="advocate-help-text--error">{{ errorSignal()?.name![0] | ErrorTranslate}}
          </p>
        </div>
      </div>
    </ng-container>

    <div class="advocate-form-row">
      <advocate-ui-button *ngIf="!isAddNewAdvertiser" state="secondary" [isCurved]="true"
        [name]="'CAMPAIGN.ADD_NEW_ADVERTISER' | translate"
        (onChange)="isAddNewAdvertiser = !isAddNewAdvertiser"></advocate-ui-button>
      <advocate-ui-button *ngIf="isAddNewAdvertiser" state="secondary" [isCurved]="true"
        [name]="'GENERAL.ADD' | translate" (onChange)="addAdvertiser()"></advocate-ui-button>
      <div class="advocate-form-row">
      </div>
    </div>

    <div class="advocate-form-row">
      <div class="advocate-form-row__label advocate-form-label">
        <label for="name-campaign" class="advocate-form-label__content">{{'CAMPAIGN.NAME_YOUR_CAMPAIGN' |
          translate}}</label>
        <span class="advocate-required-field"></span>
        &nbsp;<advocate-ui-info-icon [infoText]="campaignNameHoverText" [showMatTooltip]="false"
          [translateYPercentage]="-60" />
      </div>

      <div class="advocate-form-row__content">
        <input data-cy="campaignName-input" type="text" id="name-campaign" formControlName="campaignName"
          autocomplete="off" class="cux-textbox cux-textbox--stretched"
          [ngClass]="{'cux-textbox--error': campaignStepErrorSignal() && campaignStepErrorSignal()?.name}">
      </div>
      <div data-cy="campaignName-err" class="advocate-help-text"
        *ngIf="campaignStepErrorSignal() && campaignStepErrorSignal()?.name?.[0]">
        <p class="advocate-help-text--error">{{ campaignStepErrorSignal()?.name![0] | ErrorTranslate}}</p>
      </div>
    </div>
  </form>

  <div *ngIf="campaignStepErrorSignal() && campaignStepErrorSignal()?.generalError" class="stepper-error-disclaimer">
    <span> {{campaignStepErrorSignal()?.generalError}} </span>
  </div>
</div>