<form [formGroup]="proximityFormGroup">
    <div class="advocate-form-row">
        <div class="advocate-form-subrow">
            <div class="advocate-form-subrow__col advocate-form-subrow__col--stretched">
                <div class="advocate-form-subrow__label">
                    <label for="latitude" class="advocate-form-label">{{ "GEOFENCE.LATITUDE" | translate }}
                    </label>
                    <span class="advocate-required-field">&nbsp;</span>
                </div>
                <div class="advocate-form-row__content">
                    <input data-cy="latitude-yt-targeting" type="number" id="latitude" autocomplete="off"
                        formControlName="latitude"
                        [ngClass]="{'cux-textbox--error': proximityError && proximityError.latitude}"
                        class="cux-textbox cux-textbox--stretched" />
                </div>
                <div class="advocate-help-text" *ngIf="proximityError && proximityError?.latitude?.[0]">
                    <p class="advocate-help-text--error">{{ proximityError.latitude![0] | ErrorTranslate}}</p>
                </div>
            </div>

            <div class="advocate-form-subrow__col advocate-form-subrow__col--stretched">
                <div class="advocate-form-subrow__label">
                    <label for="longitude" class="advocate-form-label">{{ "GEOFENCE.LONGITUDE" | translate }}</label>
                    <span class="advocate-required-field">&nbsp;</span>
                </div>
                <div class="advocate-form-row__content">
                    <input data-cy="longitude-yt-targeting" type="number" id="longitude" autocomplete="off"
                        formControlName="longitude"
                        [ngClass]="{'cux-textbox--error': proximityError && proximityError.longitude}"
                        class="cux-textbox cux-textbox--stretched" />
                </div>
                <div class="advocate-help-text" *ngIf="proximityError && proximityError?.longitude?.[0]">
                    <p class="advocate-help-text--error">{{ proximityError.longitude![0] | ErrorTranslate }}</p>
                </div>
            </div>
            <div class="advocate-form-subrow__col">
                <div class="advocate-form-subrow__label">
                    <label class="advocate-form-label"></label>
                </div>
                <div class="advocate-form-row__content">
                    <advocate-ui-button class="container-input--button" [isCurved]="true" state="secondary" size="small"
                        [name]="'GEOFENCE.CLEAR_ALL' | translate" (onChange)="clearLatLong()"></advocate-ui-button>
                </div>
            </div>
        </div>
    </div>

    <div class="advocate-form-row">
        <div class="advocate-form-subrow">
            <div class="advocate-form-subrow__col advocate-form-subrow__col--stretched">
                <div class="advocate-form-subrow__label">
                    <label for="latitude" class="advocate-form-label">{{ "GEOFENCE.RADIUS" | translate }}</label>
                    <span class="advocate-required-field">&nbsp;</span>
                </div>
                <div class="advocate-form-row__content">
                    <advocate-ui-slider data-cy="radius-slider" [form]="form" [control]="proximityValueControl" />
                </div>
                <div class="advocate-help-text" *ngIf="proximityError && proximityError?.radius?.[0]">
                    <p class="advocate-help-text--error">{{ proximityError.radius![0] | ErrorTranslate }}</p>
                </div>
            </div>

            <div class="advocate-form-subrow__col advocate-form-subrow__col--stretched">
                <div class="advocate-form-subrow__label">
                    <label for="cpc-bid" class="advocate-form-label">{{ "GEOFENCE.UNIT" | translate }}</label>
                    <span class="advocate-required-field">&nbsp;</span>
                </div>
                <div class="advocate-form-row__content">
                    <advocate-ui-dropdown [form]="form" [control]="proximityUnitTypeControl"
                        [options]="proximityUnitType"
                        [title]="'GEOFENCE.SELECT_A_UNIT' | translate"></advocate-ui-dropdown>
                </div>
            </div>
        </div>
    </div>
</form>

<div class="align-btn">
    <advocate-ui-button [disabled]="proximityFormGroup.invalid" [isCurved]="true"
        [state]="proximityFormGroup.invalid ? 'Disabled' : 'secondary'" size="medium"
        [name]="'GEOFENCE.ADD_PROXIMITY_TARGET' | translate" (onChange)="addProximityTarget()"></advocate-ui-button>

    <div *ngIf="proximityError && proximityError.message" #tooltip="matTooltip" [matTooltip]="proximityError.message"
        matTooltipPosition="right">
        <advocate-icon size="extra-large" state="danger" name="error"></advocate-icon>
    </div>

</div>

<advocate-ui-proximity-table data-cy="proximity-table-yt" class="mt-3"></advocate-ui-proximity-table>