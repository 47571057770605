<div data-cy="budget-overspend-error-dialog" class="dialog-container">
    <advocate-icon (click)="onNoClick(false)" class="dialog-container__cross-icon" [isSVG]="true"
        svgName="cross.svg"></advocate-icon>

    <div class="dialog-container__icons">

        <img src="../../../../../assets/images/overspend-error-1.svg">
        <img src="../../../../../assets/images/overspend-error-2.svg">
        <h2>Hold on!</h2>

        <div class="text-container">
            {{'OVER_SPEND.ERROR_TEXT_1' | translate}}
            <br><br>
            <p [innerHTML]="'OVER_SPEND.ERROR_TEXT_2' | translate"></p>
            <br><br>
            <b>{{'OVER_SPEND.ERROR_TEXT_3' | translate}}</b>
        </div>
    </div>
    <div class="dialog-container__btns">
        <advocate-ui-button data-cy="topup-now-link" [isCurved]="true" size="large"
            [name]="'OVER_SPEND.TOP_UP_NOW' | translate" (click)="onNoClick(true)"></advocate-ui-button>
    </div>
</div>