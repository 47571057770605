import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription, take } from 'rxjs';
import { CampaignNoMenClature } from '../../../models';
import { CampaignsService } from '../../../services/campaigns.service';
import { FormService } from '../../../services/form.service';
import { InternalService } from '../../../services/internal.service';
import { PrefillCampaignsService } from '../../../services/prefillCampaign.service';
import { ButtonComponent } from '../../../shared/button/button.component';
import { IconComponent } from '../../../shared/icon/icon.component';
import { UtilityViewModelService } from '../../view-model/utility-vm.service';

@Component({
    selector: 'advocate-ui-summary-dialog',
    templateUrl: './summary-dialog.component.html',
    styleUrls: ['./summary-dialog.component.scss'],
    standalone: true,
    imports: [IconComponent, ButtonComponent, TranslateModule, CommonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryDialogComponent implements OnDestroy {
    private subs = new Subscription();
    campaignType = this.internalService.selectedTabSignal()?.tabName;
    protected featureFlag: boolean = this.uvmService.featureFlagOnDevAlone();
    constructor(private route: Router, public dialogRef: MatDialogRef<SummaryDialogComponent>,
        private campaignService: CampaignsService, private formService: FormService, private uvmService: UtilityViewModelService,
        private internalService: InternalService, private prefillCampaignsService: PrefillCampaignsService) { }


    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    navigateHome(r: string): void {
        this.dialogRef.close();
        this.route.navigate([r]);
    }

    onNoClick(): void {
        this.dialogRef.close();
        this.route.navigate(['/home/reports/campaign-info', this.campaignService.campaignIdSignal()], { queryParams: { type: 'view' } });
    }

    /**
     * Create a duplicate campaign without creatives, changing the platform ID
     * @param type would be Programmatic or Youtube only
     */
    duplicateCampaign(type: string): void {
        this.uvmService.getCampaignNoMenClatureEffect().pipe(take(1)).subscribe((noMenClatureData: CampaignNoMenClature | null) => {
            const platformIdForType: number = noMenClatureData?.campaign_platform_id.filter(v => v.name === type)[0].id as number;
            this.formService.form.get('platformId')?.setValue(platformIdForType);
            this.clearCreativeFormGroup();

            this.subs.add(this.uvmService.duplicateActionCampaignEffect(this.campaignService.campaignIdSignal()!, 'duplicate-as-different-platform', +platformIdForType).subscribe({
                next: (res) => {
                    this.prefillCampaignsService.prefill(res);
                    this.dialogRef.close();
                },
                error: (err) => {
                    this.internalService.showMessage(err.statusText);
                }
            }));
        });
    }

    clearCreativeFormGroup(): void {
        // Access each array and clear it
        (this.formService.creativeFormGroup.get('youtubeCreativesArray') as any).clear();
        (this.formService.creativeFormGroup.get('programmaticCreativesArray') as any).clear();
    }

}
