<form [formGroup]="form" class="custom-datepicker-container" [class.custom-datepicker-container--height]="isSmall"
  [ngClass]="{'date-error': error, 'background-color': !isDisplayWhite}">

  <mat-datepicker-toggle matIconSuffix [for]="picker" class="toggle-button">
    <mat-icon matDatepickerToggleIcon>calendar_month</mat-icon>
  </mat-datepicker-toggle>

  <input matInput [matDatepicker]="picker" [min]="minDate" [placeholder]="label" [formControl]="control"
    class="datepicker-input" [ngClass]="{'background-color': !isDisplayWhite}" />
  <mat-datepicker #picker></mat-datepicker>
</form>