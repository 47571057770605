import { CommonModule } from '@angular/common';
import { Component, Input, SecurityContext } from '@angular/core';
import { MatTooltipModule, TooltipPosition } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'advocate-ui-info-icon',
  standalone: true,
  imports: [CommonModule, MatTooltipModule, IconComponent],
  templateUrl: './info-icon.component.html',
  styleUrls: ['./info-icon.component.scss']
})
export class InfoIconComponent {
  @Input() infoText: string = '';
  showInfo: boolean = false;
  @Input() translateYPercentage: number = -50;
  @Input() translateXPercentage: number = 0;
  @Input() showMatTooltip: boolean = true;
  @Input('matTooltipDisabled') disabled: boolean = false;
  @Input('matTooltipHideDelay') hideDelay: number = 0;
  @Input('matTooltipPosition') position: TooltipPosition = 'right';
  @Input('matTooltipPositionAtOrigin') positionAtOrigin: boolean = true;
  @Input('matTooltipShowDelay') showDelay: number = 0;
  @Input('matTooltipClass') tooltipClass: any;

  constructor(private sanitizer: DomSanitizer) { }

  get sanitizedInfoText() {
    return this.sanitizer.sanitize(SecurityContext.HTML, this.infoText) || '';
  }

}
