import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, WritableSignal, signal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { CampaignPostResponseData, PlatformTypeEnum, TabOptions, UserData } from '../../models';
import { FormService } from '../../services/form.service';
import { DisclaimerComponent } from '../../shared/disclaimer/disclaimer.component';
import { IconComponent } from '../../shared/icon/icon.component';
import { SideHeaderComponent } from '../../shared/side-header/side-header.component';
import { AuthService } from '../auth/auth.service';
import { UtilityViewModelService } from '../view-model/utility-vm.service';

@Component({
  selector: 'advocate-ui-new-campaign',
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    SideHeaderComponent,
    MatTooltipModule,
    DisclaimerComponent,
    RouterModule,
    TranslateModule
  ],
  templateUrl: './new-campaign.component.html',
  styleUrls: ['./new-campaign.component.scss'],
})
export class NewCampaignComponent implements OnDestroy {
  userInfo$: Observable<UserData | null> = this.utilityService.getLoginInfoEffect();
  managedModeOff = this.authService.getManagedModeOff();
  readOnlyUser = this.authService.getreadonlyUser();
  public errorSignals: WritableSignal<string | null> = signal(null);
  public tabOptions: TabOptions[] = [
    {
      tabName: PlatformTypeEnum.Programmatic,
      hovered: false,
      selected: false
    },
    {
      tabName: PlatformTypeEnum.Search,
      hovered: false,
      selected: false,
    },
    {
      tabName: PlatformTypeEnum.YouTube,
      hovered: false,
      selected: false,
    },
  ];
  public form: FormGroup = this.formService.form;
  private subs = new Subscription();

  get isAnyTabSelected(): boolean {
    return this.tabOptions.some(tab => tab.selected);
  }

  constructor(private router: Router,
    private formService: FormService,
    private utilityService: UtilityViewModelService,
    private authService: AuthService) { }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  isHoveredOrSelected(tabOption: TabOptions): boolean {
    return tabOption.selected ? true : tabOption.hovered ? true : false;
  }

  tabSelected(tabOption: TabOptions): void {
    const checkSelected = this.tabOptions.find((obj) => obj.selected === true);
    if (checkSelected) checkSelected.selected = false;
    const updateTabSelection = this.tabOptions.find(
      (obj) => obj.tabName === tabOption.tabName
    );
    if (updateTabSelection)
      updateTabSelection.selected = !updateTabSelection.selected;
    this.navigateTo();
  }

  navigateTo(): void {
    this.formService.form.reset();
    this.formService.adsFormGroup.reset();
    this.formService.environmentsArray.clear();
    this.formService.languagesArray.clear();
    this.formService.locationsArray.clear();
    this.formService.demographicCountriesArray.clear();
    this.formService.adsCreativeArray.clear();
    this.formService.proximityFormArray.clear();
    this.formService.interestsArray.clear();
    this.formService.targetingFormArray.clear();
    this.formService.negativeFormArray.clear();
    this.formService.programmaticProximityFormArray.clear();
    this.formService.ageGroupsArray.clear();
    this.formService.genderGroupsArray.clear();
    this.formService.devicesGroupsArray.clear();
    this.formService.programmaticCreativesArray.clear();
    this.formService.youtubeCreativesArray.clear();
    this.formService.whiteListedKeywordsArray.clear();
    this.formService.blackListedKeywordsArray.clear();
    this.formService.whiteListedDomainsArray.clear();
    this.formService.blackListedDomainsArray.clear();
    this.formService.runningDaysArray.clear();
    this.formService.contractIdArray.clear();
    this.formService.geofenceListArray.clear();
    this.formService.programmaticCreativeFormGroup.reset();
    this.formService.youtubeCreativeFormGroup.reset();
    this.formService.searchHeadlinesFormArray.clear();
    this.formService.searchDescriptionsFormArray.clear();
    this.formService.congressionalDistrictListArray.clear();
    this.formService.postalCodeCountriesArray.clear();
    this.formService.searchPostalCodeCountriesArray.clear();
    this.formService.creativeFormGroup.get('canRemoveCreative')?.setValue(true);
    const checkSelected: TabOptions = this.tabOptions.find((obj) => obj.selected === true) as TabOptions;
    const routeName: string = checkSelected.tabName.toLowerCase();
    if (checkSelected.tabName !== PlatformTypeEnum.Programmatic) {
      this.subs.add(this.utilityService.createAnCampaignEffect(checkSelected.tabName as PlatformTypeEnum).subscribe({
        next: (resData: CampaignPostResponseData) => {
          this.errorSignals.set(null);
          const url = checkSelected.tabName === PlatformTypeEnum.YouTube ? `./home/${routeName}` : `./home/${routeName}/${resData.campaign.step0.id}`;
          this.router.navigate([url]);
        },
        error: (error: HttpErrorResponse) => this.errorSignals.set(`${error.status}: ${error.statusText}`)
      }));
    } else {
      this.router.navigate([`./home/${routeName}/creative-options`]);
    }
  }

  navigateHome(): void {
    this.router.navigate(['/']);
  }

  navigateToVerification(event: Event): void {
    event.preventDefault();
    this.router.navigate(['/home/verification']);
  }
}
