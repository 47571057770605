import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, WritableSignal, signal } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, map, take, tap } from 'rxjs';
import { AdvertiserErrorInterface, AdvertiserItemModel, AdvertiserModel, AdvertiserReponseDataModel, AdvertisersDataModel, AdvocateOption, CampaignGetReponseData, PlatformTypeEnum, Step1ErrorInterface, TabOptions } from '../../../models';
import { FormService } from '../../../services/form.service';
import { InternalService } from '../../../services/internal.service';
import { ButtonComponent } from '../../../shared/button/button.component';
import { DropDownComponent } from '../../../shared/dropdown/dropdown.component';
import { IconComponent } from '../../../shared/icon/icon.component';
import { InfoIconComponent } from '../../../shared/info-icon/info-icon.component';
import { BackendErrorTranslate } from '../../../shared/pipes/backend-error-i18n.pipe';
import { ErrorHandlingViewModelService } from '../../view-model/error-handling-vm.service';
import { UtilityViewModelService } from '../../view-model/utility-vm.service';

@Component({
  selector: 'advocate-ui-campaign-page',
  templateUrl: './campaign-page.component.html',
  styleUrls: ['./campaign-page.component.scss'],
  standalone: true,
  imports: [CommonModule, ButtonComponent, FormsModule, ReactiveFormsModule, DropDownComponent, IconComponent, InfoIconComponent, TranslateModule, BackendErrorTranslate],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CampaignPageComponent implements OnInit, OnDestroy {
  selectedTabSignal: WritableSignal<TabOptions | null> = this.internalService.selectedTabSignal;
  public errorSignal: WritableSignal<AdvertiserErrorInterface | null> = signal(null);
  private subs = new Subscription();
  public campaignNameFormGroup: FormGroup;
  public advertisersNames$!: Observable<AdvocateOption[]>;
  public campaignStepErrorSignal: WritableSignal<Step1ErrorInterface | null> = this.errorService.campaignStepErrorSignal;
  isAddNewAdvertiser = false;
  isSelectedYoutube = this.selectedTabSignal()?.tabName === PlatformTypeEnum.YouTube;
  campaignRunningHoverText: string = '';
  campaignNameHoverText: string = '';

  get selectedAdvertiserControl(): FormControl {
    const currentUrl = this.router.url;
    this.campaignNameFormGroup = currentUrl.includes('search') ? this.formService.searchCampaignNameFormGroup : this.formService.campaignNameFormGroup;
    return this.campaignNameFormGroup.get('selectedAdvertiser') as FormControl;
  }

  constructor(private utilityService: UtilityViewModelService, private formService: FormService, private errorService: ErrorHandlingViewModelService,
    private internalService: InternalService, private translate: TranslateService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    const currentUrl = this.router.url;
    this.campaignNameFormGroup = currentUrl.includes('search') ? this.formService.searchCampaignNameFormGroup : this.formService.campaignNameFormGroup;

    if (id && currentUrl.includes('step1')) {
      this.utilityService.getCampaignStepsEffect(+id).pipe(take(1)).subscribe({
        next: (res: CampaignGetReponseData) => {
          this.campaignNameFormGroup.patchValue({
            selectedAdvertiser: res?.campaign.step1.advertiser_id,
            advertiserName: null,
            assignedDebit: 0,
            campaignName: res?.campaign.step1.name
          });
        },
        error: _ => this.router.navigate(['/home'])
      })
    }


    this.getAdvertisersNames();

    this.updateTranslation();
    this.subs.add(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateTranslation();
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private updateTranslation(): void {
    this.campaignNameHoverText = this.isSelectedYoutube
      ? this.translate.instant('CAMPAIGN.YOUTUBE_CAMPAIGN_NAME_HOVER_TEXT')
      : this.translate.instant('CAMPAIGN.NON_YOUTUBE_CAMPAIGN_NAME_HOVER_TEXT');

    this.campaignRunningHoverText = this.isSelectedYoutube
      ? this.translate.instant('CAMPAIGN.YOUTUBE_CAMPAIGN_RUNNING_HOVER_TEXT')
      : this.translate.instant('CAMPAIGN.NON_YOUTUBE_CAMPAIGN_RUNNING_HOVER_TEXT');
  }

  addAdvertiser(): void {
    const data: AdvertiserModel = {
      name: this.campaignNameFormGroup.get('advertiserName')?.value
    }
    this.subs.add(this.utilityService.createAdvertiserEffect(data).subscribe({
      next: (res: AdvertiserReponseDataModel) => {
        this.isAddNewAdvertiser = !this.isAddNewAdvertiser;
        this.isAddNewAdvertiser = false;
        this.selectedAdvertiserControl.setValue(res.advertiser.id);
        this.selectedAdvertiserControl.updateValueAndValidity();
        this.getAdvertisersNames();
        this.errorSignal.set(null);
      },
      error: (error: HttpErrorResponse) => {
        if (error.status !== 422 || !error.error) {
          this.errorSignal.set({ generalError: `${error.status}: ${error.statusText}` });
          return;
        }
        const display: AdvertiserErrorInterface = error.error;
        this.errorSignal.set(display);
      }
    }))
  }

  getAdvertisersNames(): void {
    this.advertisersNames$ = this.utilityService.getAdvertiserEffect().pipe(
      map((data: AdvertisersDataModel) => {
        return data.advertisers.items.map((d: AdvertiserItemModel) => {
          const AdvocateOption: AdvocateOption = {
            id: `${d.id}`,
            name: d.name
          }
          return AdvocateOption;
        })
      }),
      tap(options => {
        const preselectedAdvertiserId = this.selectedAdvertiserControl?.value;
        const preselectedOption = options.find(option => option.id === preselectedAdvertiserId + '');

        if (preselectedOption) {
          this.selectedAdvertiserControl.setValue(preselectedOption);
        }
      })
    );
  }
}
