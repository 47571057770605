<ng-container *ngIf="{type: (keyWordType$ | async)} as vm">
    <div class="container">
        <div class="container_header">
            <span>{{ "GENERAL.KEYWORD" | translate }} &nbsp;
                <advocate-ui-info-icon [infoText]="'CAMPAIGN.KEYWORDS_DES' | translate" />
            </span>
        </div>

        <div class="container_budget">
            <div class="container_budget--price">
                <form class="advocate-form" [formGroup]="targetingKeywordFormGroup">
                    <div class="advocate-form-row">
                        <div class="advocate-form-subrow">
                            <div class="advocate-form-subrow__col advocate-form-subrow__col--stretched">
                                <div class="advocate-form-subrow__label">
                                    <label for="keyword" class="advocate-form-label">{{ "GENERAL.KEYWORD" | translate
                                        }}</label>
                                    <span class="advocate-required-field"></span>
                                </div>
                                <div class="advocate-form-row__content">
                                    <input data-cy="keywords_search" type="text" id="keyword" autocomplete="off"
                                        formControlName="keyword" class="cux-textbox cux-textbox--stretched"
                                        [ngClass]="{'cux-textbox--error': searchKeywordErrorSignal() && searchKeywordErrorSignal()?.keyword?.[0]}" />
                                </div>
                                <div class="advocate-help-text"
                                    *ngIf="searchKeywordErrorSignal() && searchKeywordErrorSignal()?.keyword?.[0]">
                                    <p class="advocate-help-text--error">{{
                                        searchKeywordErrorSignal()?.keyword![0] | ErrorTranslate }}
                                    </p>
                                </div>
                            </div>

                            <div class="advocate-form-subrow__col advocate-form-subrow__col--stretched">
                                <div class="advocate-form-subrow__label">
                                    <label class="advocate-form-label">{{ "CAMPAIGN.MATCH_TYPE" | translate }}
                                        <span class="advocate-required-field"></span>
                                        &nbsp;<advocate-ui-info-icon
                                            [infoText]="'CAMPAIGN.MATCH_TYPE_DESCRIPTION' | translate"
                                            [showMatTooltip]="false" />
                                    </label>
                                </div>
                                <div class="advocate-form-row__content">
                                    <advocate-ui-dropdown data-cy="keywords_search_dropdown" [options]="vm.type"
                                        [form]="targetingKeywordFormGroup" [control]="targetingMatchType"
                                        [title]="'TARGETING.SELECT_AN_OPTION' | translate"></advocate-ui-dropdown>
                                </div>
                            </div>


                            <div class="advocate-form-subrow__col advocate-form-subrow__col--stretched">
                                <div class="advocate-form-subrow__label">
                                    <label class="advocate-form-label"></label>
                                </div>
                                <div class="advocate-form-row__content">
                                    <advocate-ui-button data-cy="keywords_search_button" [isCurved]="true"
                                        [state]="targetingKeywordFormGroup.invalid ? 'Disabled' : 'secondary'"
                                        size="large" [disabled]="targetingKeywordFormGroup.invalid"
                                        (onChange)="onAddKeyword()"
                                        [name]="'CAMPAIGN.ADD_KEYWORD' | translate"></advocate-ui-button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="advocate-form-row">
                        <mat-chip-listbox>
                            <mat-chip *ngFor="let chip of targetingFormArray?.controls">
                                {{chip?.value.match_type_label}}: {{chip?.value.keyword}}
                                <button (click)="removeTargetingKeyword(chip.value)" matChipRemove>
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip>
                        </mat-chip-listbox>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="container mt-3">
        <div class="container_header">
            <span>{{ "CAMPAIGN.NEGATIVE_KEYWORDS" | translate }} &nbsp;
                <advocate-ui-info-icon [infoText]="'CAMPAIGN.NEG_KEYWORDS_DES' | translate" />
            </span>
        </div>

        <div class="container_dates">
            <div class="container_dates--row1">
                <form class="advocate-form" [formGroup]="negativeKeywordFormGroup">
                    <div class="advocate-form-row">
                        <div class="advocate-form-subrow">
                            <div class="advocate-form-subrow__col advocate-form-subrow__col--stretched">
                                <div class="advocate-form-subrow__label">
                                    <label for="negative-keyword" class="advocate-form-label">{{ "GENERAL.KEYWORD" |
                                        translate }}</label>
                                    <span class="advocate-required-field"></span>
                                </div>
                                <div class="advocate-form-row__content">
                                    <input data-cy="neg_keywords_search" type="text" id="negative-keyword"
                                        autocomplete="off" formControlName="keyword"
                                        class="cux-textbox cux-textbox--stretched"
                                        [ngClass]="{'cux-textbox--error': searchKeywordErrorSignal() && searchKeywordErrorSignal()?.negative_keyword?.[0]}" />
                                </div>
                                <div class="advocate-help-text"
                                    *ngIf="searchKeywordErrorSignal() && searchKeywordErrorSignal()?.negative_keyword?.[0]">
                                    <p class="advocate-help-text--error">{{
                                        searchKeywordErrorSignal()?.negative_keyword![0] | ErrorTranslate}}
                                    </p>
                                </div>
                            </div>

                            <div class="advocate-form-subrow__col advocate-form-subrow__col--stretched">
                                <div class="advocate-form-subrow__label">
                                    <label class="advocate-form-label">{{ "CAMPAIGN.MATCH_TYPE" | translate }}
                                        <span class="advocate-required-field"></span>
                                        &nbsp;<advocate-ui-info-icon
                                            [infoText]="'CAMPAIGN.MATCH_TYPE_DESCRIPTION' | translate"
                                            [showMatTooltip]="false" />
                                    </label>
                                </div>
                                <div class="advocate-form-row__content">
                                    <advocate-ui-dropdown data-cy="neg_keywords_search_dropdown" [options]="vm.type"
                                        [form]="negativeKeywordFormGroup" [control]="negativeMatchType"
                                        [title]="'TARGETING.SELECT_AN_OPTION' | translate"></advocate-ui-dropdown>
                                </div>
                            </div>


                            <div class="advocate-form-subrow__col advocate-form-subrow__col--stretched">
                                <div class="advocate-form-subrow__label">
                                    <label class="advocate-form-label"></label>
                                </div>
                                <div class="advocate-form-row__content">
                                    <advocate-ui-button data-cy="neg_keywords_search_button" [isCurved]="true"
                                        [state]="negativeKeywordFormGroup.invalid ? 'Disabled' : 'secondary'"
                                        size="large" [disabled]="negativeKeywordFormGroup.invalid"
                                        (onChange)="onAddKNegativeKeyword()"
                                        [name]="'CAMPAIGN.ADD_NEGATIVE_KEYWORD' | translate"></advocate-ui-button>
                                </div>
                            </div>

                        </div>
                        <div>
                        </div>
                    </div>
                    <div class="advocate-form-row">
                        <mat-chip-listbox>
                            <mat-chip *ngFor="let chip of negativeFormArray?.controls">
                                {{chip?.value.match_type_label}}: {{chip?.value.keyword}}
                                <button (click)="removeNegativeKeyword(chip.value)" matChipRemove>
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip>
                        </mat-chip-listbox>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div *ngIf="searchKeywordErrorSignal() && searchKeywordErrorSignal()?.generalError"
        class="stepper-error-disclaimer mt-3">
        <span> {{searchKeywordErrorSignal()?.generalError}} </span>
    </div>
</ng-container>