<div class="container">
    <advocate-ui-page-description
        [content]="'TARGETING.GEOGRAPHY.DESCRIPTION' | translate"></advocate-ui-page-description>

    <form [formGroup]="form" class="advocate-form">
        <div class="advocate-form-row">
            <div class="advocate-form-row__label advocate-form-label">
                <label for="location" class="advocate-form-label__content">{{ "TARGETING.GEOGRAPHY.LOCATION" | translate
                    }}</label>
                <span class="advocate-required-field">&nbsp;</span>
            </div>

            <div class="advocate-form-row__content">
                <advocate-ui-dropdown data-cy="locations_search" [form]="form" [controlArray]="locationsArray"
                    [isLive]="true" [multiple]="true" [liveSearchControl]="liveSearchControl"
                    [options]="locations$ | async"
                    [title]="'TARGETING.SELECT_AN_OPTION' | translate"></advocate-ui-dropdown>

            </div>
            <i>{{ "TARGETING.GEOGRAPHY.SEARCH_LOCATION" | translate }}</i>

            <div class="advocate-row-chips" [ngClass]="{ 'chip-container': locationsArray.controls.length > 0 }">
                <advocate-ui-chips [chips]="chips$ | async" [controlArray]="locationsArray" [withRemove]="true" />
            </div>
        </div>

        <advocate-ui-or-divider />

        <advocate-ui-bulk-postal-codes [form]="form" [postalCodeCountriesArray]="postalCodeCountriesArray"
            [locationsArray]="locationsArray" />

        <advocate-ui-or-divider />

        <advocate-ui-proximity [form]="form" [proximityUnitType]="proximityUnitType"
            [proximityError]="proximityError$ | async"></advocate-ui-proximity>
    </form>
</div>