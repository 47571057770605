import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, WritableSignal } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, map, startWith } from 'rxjs';
import { AdvocateOption, ChipsModel, PlatformTypeEnum, TabOptions } from '../../../../models';
import { FormService } from '../../../../services/form.service';
import { InternalService } from '../../../../services/internal.service';
import { ChipsComponent } from '../../../../shared/chips/chips.component';
import { PillsComponent } from '../../../../shared/chips/pills.component';
import { DropDownComponent } from '../../../../shared/dropdown/dropdown.component';
import { IconComponent } from '../../../../shared/icon/icon.component';
import { InfoIconComponent } from '../../../../shared/info-icon/info-icon.component';

@Component({
  selector: 'advocate-ui-device-language',
  standalone: true,
  imports: [CommonModule, ChipsComponent, IconComponent, DropDownComponent, InfoIconComponent, PillsComponent, TranslateModule],
  templateUrl: './device-language.component.html',
  styleUrls: ['./device-language.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceLanguageComponent {
  @Input() set devices(value: AdvocateOption[] | null) {
    this._devices = value;
    if (this.devicesArray.length === 0 && value) {
      this.populateDevicesGroups(value);
    }
  }

  get devices(): AdvocateOption[] | null {
    return this._devices;
  }

  @Input() languages!: AdvocateOption[] | null;

  private _devices: AdvocateOption[] | null = null;
  form: FormGroup;
  PlatformTypeEnum = PlatformTypeEnum;
  selectedTabSignal: WritableSignal<TabOptions | null> = this.internalService.selectedTabSignal;

  chips$: Observable<ChipsModel[]> = this.languagesArray.valueChanges.pipe(
    startWith(this.languagesArray.value),
    map((values: { id: number; name?: string; value?: string }[]) => {
      return values.map((m: any) => {
        const temp: ChipsModel = {
          id: m.id,
          label: m.value ? m.value : m.name
        }
        return temp;
      })
    }));

  get languagesArray(): FormArray {
    return this.router.url.includes('search') ? this.formService.searchLanguagesArray : this.formService.languagesArray;
  }

  get devicesArray(): FormArray {
    return this.form.get('devices') as FormArray;
  }

  constructor(private formService: FormService, private internalService: InternalService, private router: Router) {
    this.form = this.router.url.includes('search') ? this.formService.searchTargetingFormGroup : this.formService.targetingFormGroup
  }

  populateDevicesGroups(devices: AdvocateOption[]): void {
    devices.forEach(device => {
      this.formService.devicesFormGroup.setValue({
        id: device.id,
        name: device.name
      });
      this.devicesArray.push(this.formService.cloneFormGroup(this.formService.devicesFormGroup));
      this.formService.devicesFormGroup.reset();
    });
  }
}
