import { animate, state, style, transition, trigger } from '@angular/animations';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { UtilityViewModelService } from '../../../app/view-model/utility-vm.service';
import { FormService } from '../../../services/form.service';
import { IconComponent } from '../../icon/icon.component';

/**
 * @title Table with expandable rows
 */
@Component({
    selector: 'advocate-ui-ads-table',
    styleUrls: ['ads-table.component.scss'],
    templateUrl: 'ads-table.component.html',
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: true,
    imports: [MatTableModule, NgFor, AsyncPipe, MatButtonModule, NgIf, MatIconModule, MatSortModule, IconComponent, TranslateModule],
})
export class AdsTableComponent implements OnInit, AfterViewInit, OnDestroy {
    dataSource = new MatTableDataSource();
    columnsToDisplay = ['headline_1', 'headline_2', 'headline_3', 'edit'];
    columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
    expandedElement!: null;
    adsCreativeArray: FormArray = this.formService.adsCreativeArray;
    private subs = new Subscription();

    constructor(private formService: FormService, private utilityService: UtilityViewModelService) { }

    @ViewChild(MatSort) sort!: MatSort;

    ngOnInit(): void {
        this.adsCreativeArray.clear();
        this.subs.add(this.adsCreativeArray.valueChanges.subscribe(v => {
            this.dataSource.data = v;
        }));
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    removeCreative(creativeId: number): void {
        this.utilityService.removeCreativeEffect(creativeId).subscribe(v => {
            this.removeCreativeById(v.creative.id as number);
        })
    }

    removeCreativeById(creativeId: number): void {
        const index = this.adsCreativeArray.controls.findIndex(
            (control: AbstractControl, index: number, array: AbstractControl[]) => {
                if (control instanceof FormGroup && control.get('id')) {
                    return control.get('id')?.value === creativeId;
                }
                return false;
            }
        );

        // If the FormGroup is found, remove it from the FormArray
        if (index !== -1) {
            this.adsCreativeArray.removeAt(index);
        } else {
            console.warn(`FormGroup with id ${creativeId} not found.`);
        }
    }

}

