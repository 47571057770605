<ng-container
    *ngIf="selectedTabSignal()?.tabName === PlatformTypeEnum.Programmatic ? programmaticProximityFormArray.length > 0 : proximityFormArray.length > 0">
    <h2 *ngIf="!hideHeader">{{ "GEOFENCE.PROXIMITY_VALUES" | translate }}</h2>
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
        class="mat-elevation-z8 proximity-table">

        <ng-container matColumnDef="latitude">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> {{ "GEOFENCE.LATITUDE" | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{element.latitude}} </td>
        </ng-container>

        <ng-container matColumnDef="longitude">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> {{ "GEOFENCE.LONGITUDE" | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{element.longitude}} </td>
        </ng-container>

        <ng-container matColumnDef="radius">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> {{ "GEOFENCE.RADIUS" | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{element.radius}} </td>
        </ng-container>

        <ng-container matColumnDef="radius_unit_label">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> {{ "GEOFENCE.PROXIMITY_UNIT" | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{element.radius_unit_label}} </td>
        </ng-container>

        <ng-container matColumnDef="delete">
            <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <advocate-icon class="btn" size="extra-small" state="primary" name="delete"
                    (click)="deleteRow(element)"></advocate-icon>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</ng-container>