<div data-cy="publish-campaign-dialog" class="icon">
    <advocate-icon [isSVG]="true" svgName="congrats-icon.svg"></advocate-icon>
</div>
<h2>{{ "SUMMARY.CONGRATULATIONS" | translate }}</h2>
<div class="review-text">
    <span>{{ "SUMMARY.CAMPAIGN_UNDER_REVIEW" | translate }}</span>
    <span>{{ "SUMMARY.CAMPAIGN_TIME_EST" | translate }}</span>
</div>

<div class="buttons">
    <advocate-ui-button [isCurved]="true" state="secondary" [name]="'GENERAL.HOME' | translate"
        (onChange)="navigateHome('/home')"></advocate-ui-button>
    <advocate-ui-button [state]="'primary'" [isCurved]="true" [name]="'SUMMARY.PREVIEW' | translate"
        (onChange)="onNoClick()"></advocate-ui-button>
</div>

<div class="duplicate-campaign" *ngIf="featureFlag">
    <span>{{ "SUMMARY.CREATE_ANOTHER_CAMPAIGN" | translate }}
        <span *ngIf="campaignType !== 'Programmatic'"><a class="duplicate-campaign--button"
                (click)="duplicateCampaign('Programmatic')">Programmatic</a> {{ "GENERAL.OR" | translate | uppercase }}
        </span>
        <a class="duplicate-campaign--button" (click)="duplicateCampaign('Search')">Search</a>
        <span *ngIf="campaignType !== 'YouTube'"> {{ "GENERAL.OR" | translate | uppercase }} <a
                class="duplicate-campaign--button" (click)="duplicateCampaign('YouTube')">YouTube</a></span>
    </span>
</div>