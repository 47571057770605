import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, WritableSignal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, filter, map, shareReplay, take } from 'rxjs';
import { AdvocateOption, ContactUsEnum, Location, NoMenClaturesMetaDataModel, PlatformTypeEnum, SearchCampaignGetReponseData, TabOptions, TargetingErrorInterface } from '../../../models';
import { FormService } from '../../../services/form.service';
import { InternalService } from '../../../services/internal.service';
import { UtilityService } from '../../../services/utility.service';
import { ButtonComponent } from '../../../shared/button/button.component';
import { ContactUsComponent } from '../../../shared/contact-us/contact-us.component';
import { DropDownComponent } from '../../../shared/dropdown/dropdown.component';
import { ExpansionPanelComponent } from '../../../shared/expansion-panel/expansion-panel.component';
import { IconComponent } from '../../../shared/icon/icon.component';
import { ErrorHandlingViewModelService } from '../../view-model/error-handling-vm.service';
import { UtilityViewModelService } from '../../view-model/utility-vm.service';
import { DeviceLanguageComponent } from './device-language/device-language.component';
import { GeographySearchComponent } from './geography-search/geography-search-page.component';

@Component({
    selector: 'advocate-ui-targeting-search-page',
    standalone: true,
    templateUrl: './targeting-search-page.component.html',
    styleUrls: ['./targeting-search-page.component.scss'],
    imports: [
        CommonModule,
        DropDownComponent,
        ExpansionPanelComponent,
        GeographySearchComponent,
        ButtonComponent,
        DeviceLanguageComponent,
        TranslateModule,
        IconComponent
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TargetingSearchPageComponent implements OnInit {
    form: FormGroup = this.formService.searchTargetingFormGroup;
    PlatformTypeEnum = PlatformTypeEnum;
    selectedTabSignal: WritableSignal<TabOptions | null> = this.internalService.selectedTabSignal;
    targetErrorSignal: WritableSignal<TargetingErrorInterface | null> = this.errorService.targetErrorSignal;
    private noMenClatures$: Observable<NoMenClaturesMetaDataModel> = this.utilityService.getNoMenClaturesPlatformMetaEffect().pipe(
        filter((result): result is NoMenClaturesMetaDataModel => result !== null),
        shareReplay({ refCount: true, bufferSize: 1 })
    );
    public devices$: Observable<AdvocateOption[]> = this.noMenClatures$.pipe(
        map((data: NoMenClaturesMetaDataModel) => data.eligible_values.devices.map(device => ({ id: device, name: device })))
    );

    public languages$: Observable<AdvocateOption[]> = this.noMenClatures$
        .pipe(map((data: NoMenClaturesMetaDataModel) => data.eligible_values.languages));

    public proximityUnitTypes$: Observable<AdvocateOption[] | null> =
        this.utilityService.getCampaignNoMenClatureEffect().pipe(
            map((data) => {
                if (!data) return null;
                return data?.proximity_target_radius_unit_id
            }));

    constructor(private utilityService: UtilityViewModelService,
        private internalService: InternalService,
        private dialog: MatDialog,
        private errorService: ErrorHandlingViewModelService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private formService: FormService,
        private utility: UtilityService) { }

    ngOnInit(): void {
        const id = this.activatedRoute.snapshot.paramMap.get('id');

        // Reset forms
        this.formService.searchLocationsArray.clear();
        this.formService.searchLanguagesArray.clear();
        this.formService.searchDevicesGroupsArray.clear();
        this.formService.searchProximityFormArray.clear();
        this.formService.targetingFormArray.clear();
        this.formService.postalCodeCountriesArray.clear();
        this.formService.searchTargetingFormGroup.get('postalCodes')?.reset();

        if (id && this.router.url.includes('step5')) {
            this.utilityService.getSearchCampaignStepsEffect(+id).pipe(take(1)).subscribe({
                next: (res: SearchCampaignGetReponseData) => {
                    res.campaign.step5.locations.forEach((location: Location) => {
                        this.formService.locationsFormGroup.setValue({
                            id: location.id,
                            name: location.name
                        });
                        this.formService.searchLocationsArray.push(this.formService.cloneFormGroup(this.formService.locationsFormGroup));
                        this.formService.locationsFormGroup.reset();
                    });

                    res.campaign.step5.languages?.forEach(language => {
                        this.formService.languageFormGroup.setValue(language);
                        this.formService.searchLanguagesArray.push(this.formService.cloneFormGroup(this.formService.languageFormGroup));
                        this.formService.languageFormGroup.reset();
                    });

                    res.campaign.step5.devices?.forEach((device: string) => {
                        this.formService.devicesFormGroup.setValue({
                            id: device,
                            name: device
                        })
                        this.formService.searchDevicesGroupsArray.push(this.formService.cloneFormGroup(this.formService.devicesFormGroup));
                        this.formService.devicesFormGroup.reset();
                    });

                    res.campaign.step5.proximity_targets?.forEach(proximity => {
                        this.formService.proximityFormGroup.setValue(proximity);
                        this.formService.searchProximityFormArray.push(this.formService.cloneFormGroup(this.formService.proximityFormGroup));
                        this.formService.proximityFormGroup.reset();
                    });

                    if (this.formService.searchProximityFormArray.length > 0) {
                        const blob = this.utility.generateCSV(this.formService.searchProximityFormArray.value);
                        this.formService.targetingFormGroup.get('geoFenceFile')?.patchValue({ url: window.URL.createObjectURL(blob), name: 'locations.csv' });
                    }

                    res.campaign.step4.keywords?.forEach(item => {
                        this.formService.targetingKeywordFormGroup.setValue({
                            keyword: item.keyword,
                            match_type_label: item.match_type_label,
                            active: item.active,
                            id: item.id,
                            match_type_id: item.match_type_id,
                            type_id: item.type_id,
                            type_label: item.type_label

                        });
                        this.formService.targetingFormArray.push(this.formService.cloneFormGroup(this.formService.targetingKeywordFormGroup));
                        this.formService.targetingKeywordFormGroup.reset();
                    });
                },
                error: _ => this.router.navigate(['/home'])
            })
        }
    }


    openContactModel(): void {
        this.dialog.open(ContactUsComponent, {
            width: '55rem',
            height: '56rem',
            data: { selectedOption: ContactUsEnum.Manager }
        });
    }
}
