import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';

/**
 * @title Slider with custom thumb label formatting.
 */
@Component({
    selector: 'advocate-ui-slider',
    templateUrl: 'slider.component.html',
    styleUrls: ['slider.component.scss'],
    standalone: true,
    imports: [
        MatSliderModule,
        FormsModule,
        ReactiveFormsModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderComponent {
    @Input() form: FormGroup = new FormGroup({});
    @Input() control: FormControl = new FormControl(0);
    @Input() min = 0;
    @Input() max = 100;
}
