import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { environment } from '../environments/environment';
import { AdvocateOption, CampaignNoMenClature, KeywordResponse, LoctionsResponse, NoMenClaturesMetaDataModel, ProximityTarget, UserInfo, ValidateZipCodesResponse, ZipCodeData } from '../models';
import { ContractsResponse } from '../models/contracts.model';
import { FAQs } from '../models/faqs.model';

@Injectable({
    providedIn: 'root',
})
export class UtilityService {
    private getNomenClaturesState$ = new BehaviorSubject<CampaignNoMenClature | null>(null);
    private apiUrl = environment.apiKey;
    constructor(private http: HttpClient) { }

    setGetNomenClaturesState(campaignNoMenClature: CampaignNoMenClature | null): void {
        this.getNomenClaturesState$.next(campaignNoMenClature);
    }

    getNomenClaturesState(): Observable<CampaignNoMenClature | null> {
        return this.getNomenClaturesState$;
    }

    getNomenClatures(): Observable<CampaignNoMenClature> {
        return this.http.get<CampaignNoMenClature>(`${this.apiUrl}/utility/nomenclatures`).pipe(tap(t => this.setGetNomenClaturesState(t)));
    }

    // Each time we fetch NoMenClature Data per platformId. We need to assign all age, device and gender as selected.
    getNomenClaturesPlatformMeta(platform_Id: number): Observable<NoMenClaturesMetaDataModel> {
        return this.http.get<NoMenClaturesMetaDataModel>(`${this.apiUrl}/utility/nomenclatures/platform-meta?platform_id=${platform_Id}`);
    }

    getLocations(orgId: number, campaignId: number, searchTerm: string, countryNames: string[]): Observable<LoctionsResponse> {
        let params = new HttpParams();
        let countries = countryNames.length > 0 ? countryNames[0].split(',') : [];

        countries.forEach(country => {
            params = params.append('demographic_countries[]', country);
        });

        params = params.append('search_term', searchTerm);

        return this.http.get<LoctionsResponse>(`${this.apiUrl}/organisations/${orgId}/campaigns/${campaignId}/locations`, { params });
    }

    addKeywords(orgId: number, campaignId: number, body: { type_id: number, keyword: string, match_type_id: number }): Observable<KeywordResponse> {
        return this.http.post<KeywordResponse>(`${this.apiUrl}/organisations/${orgId}/campaigns/${campaignId}/keywords`, body);
    }

    getZipCodes(countryNames: AdvocateOption[]): Observable<ZipCodeData> {
        let params = new HttpParams();
        countryNames.forEach(m => {
            params = params.append('demographic_countries[]', m.id);
        });

        return this.http.get<ZipCodeData>(`${this.apiUrl}/utility/nomenclatures/zip-code-examples`, { params });
    }

    getFaqs(): Observable<FAQs> {
        return this.http.get<FAQs>(`${this.apiUrl}/utility/faq`);
    }

    getContracts(orgId: number, campaignId: number, countryNames?: AdvocateOption[]): Observable<ContractsResponse> {
        let params = new HttpParams();
        countryNames?.forEach(m => {
            params = params.append('demographic_countries[]', m.id);
        });
        return this.http.get<ContractsResponse>(`${this.apiUrl}/organisations/${orgId}/campaigns/${campaignId}/contracts`, { params });
    }

    setPreferedLanguage(body: { language_id: number }): Observable<UserInfo> {
        return this.http.post<UserInfo>(`${this.apiUrl}/auth/preferences`, body);
    }

    validateZipCodes(orgId: number, campaignId: number, body: { countries: string[], zip_codes: string[] }): Observable<ValidateZipCodesResponse> {
        return this.http.post<ValidateZipCodesResponse>(`${this.apiUrl}/organisations/${orgId}/campaigns/${campaignId}/validate-zip-codes`, body);
    }

    generateCSV(proximityTargets: ProximityTarget[]): Blob {
        const radiusUnit = proximityTargets[0].radius_unit_label;

        // Set the CSV headers
        const headers = ['Latitude', 'Longitude', `Radius (${radiusUnit})`];

        // Map the array to extract only the relevant data for each row
        const rows = proximityTargets.map((target) => {
            return [
                target.latitude,
                target.longitude,
                target.radius
            ];
        });

        // Create the CSV string
        let csvContent = headers.join(',') + '\n';
        rows.forEach((row: any[]) => {
            csvContent += row.join(',') + '\n';
        });

        // Create a Blob from the CSV string
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        return blob;
    }
}