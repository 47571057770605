<div data-cy="topUpSuggestion" class="dialog-container">
    <advocate-icon (click)="onNoClick(true)" class="dialog-container__cross-icon" [isSVG]="true"
        svgName="cross.svg"></advocate-icon>

    <div class="dialog-container__oops-icon">
        <img src="../../../../../assets/images/warning.png">
    </div>

    <div class="text-container">
        <h2>{{'OVER_SPEND.WARNING_HEADLINE' | translate}} </h2>
        <br>
        <!-- <p>The budget you’ve entered is <b>{{data.campaign_projected_spend}}</b>, and that would put your balance to
            <b>{{data.organisation_projected_balance_after_publish}}</b>.
        </p> -->
        <p [innerHTML]="'OVER_SPEND.WARNING_TEXT_1' | translate:{ 
            campaign_projected_spend: data.campaign_projected_spend, 
            organisation_projected_balance_after_publish: data.organisation_projected_balance_after_publish 
        }"></p>
        <br>
        <p>{{'OVER_SPEND.WARNING_TEXT_2' | translate}}</p>
    </div>

    <div class="dialog-container__btns">
        <advocate-ui-button data-cy="topup-later" [isCurved]="true" state="secondary" size="large"
            [name]="'OVER_SPEND.TOP_UP_LATER' | translate" (click)="onNoClick(true)"></advocate-ui-button>

        <advocate-ui-button data-cy="topup-now-link" [isCurved]="true" size="large"
            [name]="'OVER_SPEND.TOP_UP_NOW' | translate" (click)="onNoClick(false)"></advocate-ui-button>
    </div>
</div>