import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { IconComponent } from "../icon/icon.component";

@Component({
    selector: 'advocate-ui-page-description',
    templateUrl: './page-description.component.html',
    standalone: true,
    imports: [CommonModule, IconComponent],
    styleUrls: ['./page-description.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageDescriptionComponent {
    @Input() content: string = '';
    @Input() colorClass: string = 'blue-bg';
}