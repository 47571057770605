import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, Subscription, switchMap } from 'rxjs';
import { AuthService } from '../../../app/auth/auth.service';
import { UtilityViewModelService } from '../../../app/view-model/utility-vm.service';
import { AdvertisersDataModel } from '../../../models';
import { ButtonComponent } from '../../button/button.component';
import { AdvertiserDialogComponent } from './advertiser-dialog/advertiser-dialog.component';

@Component({
  standalone: true,
  imports: [CommonModule, MatTableModule, MatSortModule, MatPaginatorModule, ButtonComponent, TranslateModule],
  selector: 'advocate-ui-advertisers-table',
  templateUrl: './advertisers-table.component.html',
  styleUrls: ['./advertisers-table.component.scss']
})
export class AdvertisersTableComponent implements OnDestroy {
  managedModeOff = this.authService.getManagedModeOff();
  readOnlyUser = this.authService.getreadonlyUser();
  displayedColumns: string[] = ['name', 'num_of_campaigns', 'spend', 'edit'];
  pageSize: number = 10;
  pageIndex: number = 0;
  pageSizeOptions: number[] = [15, 20, 25, 50];
  advertisertableSignal = signal({});
  tableData$ = toObservable(this.advertisertableSignal).pipe(switchMap(() => this.fetchData()));
  private subs = new Subscription();
  @Input() set data(d: { name: string } | undefined) {
    this.advertisertableSignal.set(d!);
  }

  constructor(private utilityVMService: UtilityViewModelService, private dialog: MatDialog,
    private authService: AuthService) { }

  private fetchData(page: number = 1): Observable<AdvertisersDataModel> {
    return this.utilityVMService.getAdvertiserEffect(page, this.pageSize);
  }

  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.tableData$ = this.fetchData(this.pageIndex + 1);
  }

  editAdvertiser(name: string, id: number, index: number): void {
    const dialogRef = this.dialog.open(AdvertiserDialogComponent, {
      width: '61rem',
      height: 'fit-content',
      data: { name, id, mode: 'edit' }
    });

    this.subs.add(dialogRef.afterClosed().subscribe(result => {
      if (result?.name !== name) this.advertisertableSignal.set(result);
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}