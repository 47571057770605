import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { FormArray, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { Observable, filter, map, shareReplay, startWith, take } from "rxjs";
import { AdvocateOption, NoMenClaturesMetaDataModel, ValidateZipCodesResponse } from "../../../../models";
import { FormService } from "../../../../services/form.service";
import { ButtonComponent } from "../../../../shared/button/button.component";
import { ChipsComponent } from "../../../../shared/chips/chips.component";
import { DropDownComponent } from "../../../../shared/dropdown/dropdown.component";
import { UtilityViewModelService } from "../../../view-model/utility-vm.service";

@Component({
    selector: 'advocate-ui-bulk-postal-codes',
    standalone: true,
    imports: [CommonModule, MatCheckboxModule, ButtonComponent, TranslateModule, ReactiveFormsModule, MatIconModule, DropDownComponent, ChipsComponent],
    templateUrl: './bulk-postal-codes.component.html',
    styleUrls: ['./bulk-postal-codes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkPostalCodesComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() postalCodeCountriesArray: FormArray<any>;
    @Input() locationsArray: FormArray<any>;

    isBulkUploadEnabled = false;
    errorMessage: string | null = null;
    validationSuccess = false;
    postalCodeCountries$ = this.uvmService.getNoMenClaturesPlatformMetaEffect().pipe(
        filter((result): result is NoMenClaturesMetaDataModel => result !== null),
        shareReplay({ refCount: true, bufferSize: 1 }),
        map(data => data.eligible_values.countries_with_zip_support.map(value => ({ id: value, name: value })))
    );

    chips$: Observable<{ id: number; label: string }[]>;

    constructor(private formService: FormService, private translateService: TranslateService, private uvmService: UtilityViewModelService, private cdRef: ChangeDetectorRef) { }

    ngOnInit(): void {
        if (!this.postalCodeCountriesArray) {
            this.postalCodeCountriesArray = new FormArray<any>([]);
        }

        this.chips$ = this.postalCodeCountriesArray.valueChanges.pipe(
            startWith(this.postalCodeCountriesArray.value),
            map((values: { id: number; name?: string; value?: string }[]) =>
                values.map(m => ({
                    id: m.id,
                    label: m.value || m.name || ''
                }))
            )
        );
        this.postalCodeCountriesArray.valueChanges.subscribe(s => console.log('sss', s))
    }

    toggleBulkUpload(event: any) {
        this.isBulkUploadEnabled = event.checked;
        if (!this.isBulkUploadEnabled) {
            this.form.get('postalCodes')?.reset();
            this.errorMessage = null;
            this.validationSuccess = false;
        }
    }

    clearAll() {
        this.form.get('postalCodes')?.reset();
        this.errorMessage = null;
        this.validationSuccess = false;
    }

    validatePostalCodes() {
        const rawPostalCodes = this.form.get('postalCodes')?.value || '';
        let postalCodes = rawPostalCodes
            .split(/[\n,]+/)
            .map((code: string) => code.trim())
            .filter((code: string) => code !== '');

        if (postalCodes.length === 0) {
            this.errorMessage = this.translateService.instant('TARGETING.GEOGRAPHY.POSTAL_CODES_NOT_EMPTY');
            this.validationSuccess = false;
            return;
        }

        this.errorMessage = null;

        this.uvmService.validateZipCodesEffect(postalCodes, this.postalCodeCountriesArray).pipe(take(1)).subscribe((data: ValidateZipCodesResponse) => {
            const validPostalCodes: string[] = [];
            const existingIds = new Set(this.locationsArray.value.map((location: AdvocateOption) => location.id));
            data.valid.forEach((item: { id: number; name: string; simple_name: string }) => {
                if (!existingIds.has(item.id)) {
                    this.formService.locationsFormGroup.setValue({
                        id: item.id,
                        name: item.name
                    });
                    this.locationsArray.push(this.formService.cloneFormGroup(this.formService.locationsFormGroup));
                    this.formService.locationsFormGroup.reset();
                }
                validPostalCodes.push(item.simple_name);
            });
            const invalidPostalCodes = data.invalid;

            this.form.get('postalCodes')?.setValue(validPostalCodes.join(', '));
            if (invalidPostalCodes.length > 0) {
                this.errorMessage = this.translateService.instant('TARGETING.GEOGRAPHY.INVALID_POSTAL_CODES') + ': ' + invalidPostalCodes.join(', ');
                this.validationSuccess = false;
            } else {
                this.validationSuccess = true;
            }

            this.cdRef.markForCheck();
        });
    }
}