import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { UtilityViewModelService } from '../../app/view-model/utility-vm.service';
import { ContactUsEnum, UserData } from '../../models';
import { FormService } from '../../services/form.service';
import { InternalService } from '../../services/internal.service';
import { ButtonComponent } from '../button/button.component';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'advocate-ui-contact-us',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonComponent,
    TranslateModule,
    IconComponent
  ],
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactUsComponent implements OnInit, OnDestroy {
  private subs = new Subscription();
  formData?: UserData | null;
  form: FormGroup = this.formService.contactUsGroup;
  campaignName = this.formService.campaignNameFormGroup.get('campaignName')?.value;
  selectedOption?: string;
  ContactUsEnum = ContactUsEnum;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { selectedOption: string, campaignId?: number, mailingOptions?: string[] },
    private uvmService: UtilityViewModelService,
    private formService: FormService,
    private dialogRef: MatDialogRef<ContactUsComponent>,
    private internalService: InternalService,
  ) { }

  ngOnInit(): void {
    this.subs.add(this.uvmService.getLoginInfoEffect().subscribe(res => this.formData = res));
    if (this.data) {
      this.selectedOption = this.data.selectedOption;
      const campaignId = this.data.campaignId;
      const campaignMessage = `Campaign #${campaignId}`;
      const mailingOptions = this.data.mailingOptions;

      this.form.get('name')?.setValue(this.formData?.user.name);
      this.form.get('phone')?.setValue(this.formData?.user.phone);
      this.form.get('email')?.setValue(this.formData?.user.email);

      if (this.selectedOption === ContactUsEnum.Request) {
        this.form.get('subject')?.setValue(`Detailed Report Request - ${campaignMessage}`);
        let message = 'User has requested the following detailed reports:';
        mailingOptions?.forEach((option, index) => {
          message += `\n${index + 1}. ${option}`;
        });
        this.form.get('message')?.setValue(message);
      }
      if (this.selectedOption === ContactUsEnum.Contact) this.form.get('subject')?.setValue(`Request detailed report - ${campaignMessage}`);
      if (this.selectedOption === ContactUsEnum.Manager) {
        this.form.get('message')?.setValue(`Hi, I need help setting up targeting for campaign ${this.campaignName}`);
        this.form.get('subject')?.setValue(`Targeting Page - ${campaignMessage}`);
      }
      if (this.selectedOption === ContactUsEnum.Help) {
        this.form.get('message')?.setValue(`Hi, I need help setting up creatives for campaign ${this.campaignName}`);
        this.form.get('subject')?.setValue(`Creatives Page - ${campaignMessage}`);
      }
    }
  }

  sendEnquiry() {
    this.subs.add(
      this.uvmService.postEnquiriesEffect(this.form.value).subscribe({
        next: () => {
          this.form.reset();
          this.dialogRef.close();
          this.internalService.showMessage('Enquiry submitted successfully!');
        },
        error: (error) => {
          console.error(error);
          this.dialogRef.close()
          this.internalService.showMessage('Failed to submit enquiry. Please try again.');
        }
      })
    );
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
