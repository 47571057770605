import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CampaignSpendInterface } from '../../../../../models';
import { ButtonComponent } from '../../../../../shared/button/button.component';
import { IconComponent } from '../../../../../shared/icon/icon.component';

@Component({
  selector: 'advocate-ui-budget-overspend-warning-dialog',
  templateUrl: './budget-overspend-warning-dialog.component.html',
  styleUrls: ['./budget-overspend-warning-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule, IconComponent, ButtonComponent, RouterModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BudgetOverspendWarningDialogComponent {
  data: CampaignSpendInterface;
  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: { misc: CampaignSpendInterface },
    public dialogRef: MatDialogRef<BudgetOverspendWarningDialogComponent>, private router: Router
  ) {
    this.data = this._data.misc;
  }


  onNoClick(isLater: boolean): void {
    if (!isLater) this.router.navigate(['/home/payment'], { state: { redirect: true } });
    this.dialogRef.close(isLater);
  }
}
