import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GtmService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  loadGtm() {
    if (!environment.gtmId || !isPlatformBrowser(this.platformId)) return;

    // Prevent multiple script injections
    if (document.getElementById('gtm-script')) return;

    // Construct GTM URL with authentication
    const gtmUrl = `https://www.googletagmanager.com/gtm.js?id=${environment.gtmId}`
      + (environment.gtmAuth ? `&gtm_auth=${environment.gtmAuth}` : '')
      + (environment.gtmPreview ? `&gtm_preview=${environment.gtmPreview}&gtm_cookies_win=x` : '');

    // Add GTM script to <head>
    const script = document.createElement('script');
    script.id = 'gtm-script';
    script.async = true;
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='${gtmUrl}';
      f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${environment.gtmId}');
    `;
    document.head.appendChild(script);

    // Add GTM noscript to <body>
    const noscript = document.createElement('noscript');
    const iframeUrl = `https://www.googletagmanager.com/ns.html?id=${environment.gtmId}`
      + (environment.gtmAuth ? `&gtm_auth=${environment.gtmAuth}` : '')
      + (environment.gtmPreview ? `&gtm_preview=${environment.gtmPreview}&gtm_cookies_win=x` : '');
      
    noscript.innerHTML = `<iframe src="${iframeUrl}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.appendChild(noscript);
  }
}
