export interface AdvertiserModel {
  name: string;
}

export interface AdvertiserItemModel {
  id: number;
  name: string;
  campaign_count: number;
  debit: number;
  spend: number;
  balance: number;
}

export interface AdvertiserReponseDataModel {
  advertiser: AdvertiserItemModel
}

export interface AdvertisersDataModel {
  advertisers: {
    items: AdvertiserItemModel[];
    _links: {
      self: { href: string };
      first: { href: string };
      last: { href: string };
    };
    _meta: {
      totalCount: number;
      pageCount: number;
      currentPage: number;
      perPage: number;
    };
  };
}
