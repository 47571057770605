import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { uniqueDescriptionsValidator, uniqueHeadlinesValidator } from './validators';

@Injectable({
    providedIn: 'root',
})
export class FormService {
    atLeastOneRequiredValidator(controlNames: string[]): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const group = control as FormGroup;

            const controls = controlNames.map(name => group.get(name));

            const isAnyFilled = controls.some(control => {
                if (!control) {
                    return false;
                }

                if (control instanceof FormGroup) {
                    return Object.values(control.controls).some(child =>
                        typeof child.value === 'string'
                            ? child.value.trim() !== ''
                            : !!child.value
                    );
                } else if (control instanceof FormArray) {
                    return control.length > 0;
                } else if (typeof control.value === 'string') {
                    return control.value.trim() !== '';
                } else if (typeof control.value === 'object' && control.value !== null) {
                    return Object.values(control.value).some(
                        val => typeof val === 'string' ? val.trim() !== '' : !!val
                    );
                } else {
                    return !!control.value;
                }
            });

            return isAnyFilled ? null : { atLeastOneRequired: true };
        };
    }

    public form!: FormGroup;
    public searchForm!: FormGroup;
    // public adsFormGroup!: FormGroup;
    public proximityFormGroup!: FormGroup;
    public programmaticProximityFormGroup!: FormGroup;
    public utilityFormGroup!: FormGroup;
    public locationsFormGroup!: FormGroup;
    public ageGroupsFormGroup!: FormGroup;
    public runningDaysFormGroup!: FormGroup;
    public devicesFormGroup!: FormGroup;
    public genderGroupsFormGroup!: FormGroup;
    public languageFormGroup!: FormGroup;
    public interestsFormGroup!: FormGroup;
    public whiteListedKeywordsFormGroup!: FormGroup;
    public blackListedKeywordsFormGroup!: FormGroup;
    public whiteListedDomainsFormGroup!: FormGroup;
    public blackListedDomainsFormGroup!: FormGroup;
    public programmaticCreativeFormGroup!: FormGroup;
    public youtubeCreativeFormGroup!: FormGroup;
    public targetingKeywordFormGroup!: FormGroup;
    public negativeKeywordFormGroup!: FormGroup;
    public organisationVerificationFormGroup!: FormGroup;

    constructor(private fb: FormBuilder) {
        this.initializeForm();

        this.searchForm = this.fb.group({
            campaignNameForm: this.fb.group({
                selectedAdvertiser: ['', [Validators.required]],
                advertiserName: [''],
                assignedDebit: [0],
                campaignName: ['', [Validators.required]],
            }),
            adsFormGroup: this.fb.group({
                headline_1: ['', [Validators.required, Validators.maxLength(30)]],
                headline_2: ['', [Validators.required, Validators.maxLength(30)]],
                headline_3: ['', [Validators.required, Validators.maxLength(30)]],
                additional_headlines: this.fb.array([]),
                description_1: ['', [Validators.required, Validators.maxLength(90)]],
                description_2: ['', [Validators.required, Validators.maxLength(90)]],
                additional_descriptions: this.fb.array([]),
                landing_url: ['', [Validators.required, Validators.pattern(/^(https?:\/\/)((([a-zA-Z\d]([a-zA-Z\d-]*[a-zA-Z\d])*)\.)+[a-zA-Z]{2,}|\d{1,3}(\.\d{1,3}){3}(:\d+)?)(\/[-a-zA-Z\d%_.~+]*)*(\?[;&a-zA-Z\d%_.~+=-]*)?(#[-a-zA-Z\d_]*)?$/)]],
                id: [],
                type_id: [],
                type_label: [''],
                status_id: [],
                status_label: ['']
            }, { validators: [uniqueHeadlinesValidator(), uniqueDescriptionsValidator()] }),
            adsCreativeArray: this.fb.array([]),
            searchBudgetGroup: this.fb.group({
                start_date: ['', Validators.required],
                end_date: ['', Validators.required],
                daily_budget: [0, [Validators.required, Validators.min(1), Validators.pattern(/^\d+(\.\d{1,2})?$/)]],
                cpc_bid: [0, [Validators.required, Validators.min(0.01)]]
            }),
            keywordFormGroup: this.fb.group({
                targetingFormArray: this.fb.array([]),
                negativeFormArray: this.fb.array([])
            }),
            targetingFormGroup: this.fb.group({
                locations: this.fb.array([]),
                languages: this.fb.array([]),
                devices: this.fb.array([]),
                proximityTargetsArray: this.fb.array([]),
                postalCodes: [''],
                postalCodeCountriesArray: this.fb.array([])
            }, {
                validators: this.atLeastOneRequiredValidator([
                    'locations',
                    'postalCodes',
                    'proximityTargetsArray'
                ])
            })
        })

        this.form = this.fb.group({
            platformId: ['', [Validators.required]],
            campaignNameForm: this.fb.group({
                selectedAdvertiser: ['', [Validators.required]],
                advertiserName: [''],
                assignedDebit: [0],
                campaignName: ['', [Validators.required]],
            }),
            objectiveControl: ['', [Validators.required]],
            creativeFormGroup: this.fb.group({
                youtubeCreativesArray: this.fb.array([]),
                programmaticCreativesArray: this.fb.array([]),
                canRemoveCreative: [true]
            }),
            budgetFormGroup: this.fb.group({
                startDate: ['', [Validators.required]],
                startTime: ['', [Validators.required]],
                startTimeZoneId: ['', [Validators.required]],
                endDate: ['', [Validators.required]],
                endTime: ['', [Validators.required]],
                endTimeZoneId: ['', [Validators.required]],
                lifeTimeBudget: [0, [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)]],
                dailyBudget: [0, [Validators.pattern(/^\d+(\.\d{1,2})?$/)]],
                maxBid: [],
                budgetDistributionId: ['', Validators.required],
                runningDays: this.fb.array([]),
                bid: [],
                runningTimeFrom: [''],
                runningTimeTo: [''],
            }),
            targetingFormGroup: this.fb.group({
                demographicCountries: this.fb.array([]),
                locations: this.fb.array([]),
                ageGroups: this.fb.array([]),
                genderGroups: this.fb.array([]),
                postalCodes: [''],
                postalCodeCountriesArray: this.fb.array([]),
                geofencing_file_id: [],
                proximityTargetsArray: this.fb.array([]),
                programmaticProximityArray: this.fb.array([]),
                isPoliticalCampaign: ['no'],
                environmentsArray: this.fb.array([]),
                devices: this.fb.array([]),
                languages: this.fb.array([]),
                interests: this.fb.array([]),
                keywordLanguage: [''],
                whiteListedKeywords: this.fb.array([]),
                blackListedKeywords: this.fb.array([]),
                whiteListedDomains: this.fb.array([]),
                blackListedDomains: this.fb.array([]),
                frequencyCap: ['', [Validators.pattern(/^\d+$/), Validators.min(1)]],
                frequencyCapInterval: ['', [Validators.pattern(/^\d+$/), Validators.min(1)]],
                frequencyCapIntervalId: [],
                audience: [],
                premiumNonSkippableVideo: [],
                premiumInStreamVideo: [],
                premiumPlacements: [],
                premiumMobileAppsOnly: [],
                selectedContract: this.fb.array([]),
                geofenceList: this.fb.array([]),
                selectedGeofenceOption: [],
                geoFenceFile: [],
                congressionalDistrict: this.fb.array([])
            }, {
                validators: this.atLeastOneRequiredValidator([
                    'locations',
                    'postalCodes',
                    'proximityTargetsArray',
                    'programmaticProximityArray',
                    'geofenceList',
                    'congressionalDistrict',
                    'geofencing_file_id'
                ])
            }),
            advertiserDialogGroup: this.fb.group({
                name: [null, Validators.required]
            }),
            contactUsGroup: this.fb.group({
                name: ['', Validators.required],
                subject: ['', Validators.required],
                phone: ['', [Validators.pattern('^\\+?[1-9]\\d{1,14}$')]],
                email: ['', Validators.required],
                message: ['', Validators.required],
                note: [''],
            }),
            adsCreativeArray: this.fb.array([]),
            searchBudgetGroup: this.fb.group({
                start_date: ['', Validators.required],
                end_date: ['', Validators.required],
                daily_budget: [0, [Validators.required, Validators.min(1), Validators.pattern(/^\d+(\.\d{1,2})?$/)]],
                cpc_bid: [0, [Validators.required, Validators.min(0.01)]]
            }),
            keywordFormGroup: this.fb.group({
                targetingFormArray: this.fb.array([]),
                negativeFormArray: this.fb.array([])
            }),
            filterFormGroup: this.fb.group({
                type: [''],
                advertiser: [''],
                status: [''],
                name: [''],
                includeArchive: [0]
            }),
            verificationFormGroup: fb.group({
                organisationVerificationFormArray: fb.array([]),
                is_candidate: [false],
                candidate_name: ['']
            })
        });
    }

    initializeForm(): void {
        this.proximityFormGroup = this.fb.group({
            id: [],
            active: [],
            address: [''],
            radius_unit_id: ['', Validators.required],
            radius_unit_label: [''],
            radius: [0, Validators.required],
            latitude: ['', Validators.required],
            longitude: ['', Validators.required],
        });

        this.programmaticProximityFormGroup = this.fb.group({
            radius: [0, [Validators.required]],
            latitude: ['', [Validators.required, Validators.min(-90), Validators.max(90)]],
            longitude: ['', [Validators.required, Validators.min(-180), Validators.max(180)]],
            id: [],
            radius_unit_label: ['']
        });

        this.utilityFormGroup = this.fb.group({
            id: [],
            name: ['']
        });

        this.locationsFormGroup = this.fb.group({
            id: [],
            name: ['']
        });

        this.ageGroupsFormGroup = this.fb.group({
            id: [],
            name: []
        });

        this.runningDaysFormGroup = this.fb.group({
            id: [],
            name: []
        });

        this.devicesFormGroup = this.fb.group({
            id: [],
            name: []
        });

        this.genderGroupsFormGroup = this.fb.group({
            id: [],
            name: []
        });

        this.languageFormGroup = this.fb.group({
            id: [],
            name: ['']
        });

        this.interestsFormGroup = this.fb.group({
            id: [],
            name: ['']
        });

        this.whiteListedKeywordsFormGroup = this.fb.group({
            id: [],
            name: ['']
        });

        this.organisationVerificationFormGroup = this.fb.group({
            id: [Validators.required],
            name: ['', Validators.required],
            extension: ['', Validators.required],
            mime: [''],
            client_name: [''],
            loading: [false],
            error: ['']
        });

        this.blackListedKeywordsFormGroup = this.fb.group({
            id: [],
            name: ['']
        });

        this.whiteListedDomainsFormGroup = this.fb.group({
            id: [],
            name: ['']
        });

        this.blackListedDomainsFormGroup = this.fb.group({
            id: [],
            name: ['']
        });

        this.programmaticCreativeFormGroup = this.fb.group({
            creativeType: ['', [Validators.required]],
            fileId: [''],
            fileIds: this.fb.array([], [Validators.required]),  // Ensures at least one file ID is added
            file: [''],
            name: [''],
            sponsoredBy: ['', [Validators.required, Validators.maxLength(25)]],
            landingPageUrl: ['', [Validators.required, Validators.pattern(/^(https?:\/\/)((([a-zA-Z\d]([a-zA-Z\d-]*[a-zA-Z\d])*)\.)+[a-zA-Z]{2,}|\d{1,3}(\.\d{1,3}){3}(:\d+)?)(\/[-a-zA-Z\d%_.~+]*)*(\?[;&a-zA-Z\d%_.~+=-]*)?(#[-a-zA-Z\d_]*)?$/), Validators.maxLength(500)]],
            retargeting: ['no'],
            headline: ['', Validators.maxLength(25)],
            id: [],
            statusId: [],
            statusLabel: [''],
            text: ['', Validators.maxLength(90)],
            typeId: [],
            typeLabel: [''],
            callToAction: ['', Validators.maxLength(15)]
        });

        this.youtubeCreativeFormGroup = this.fb.group({
            type_id: ['', [Validators.required]],
            type_label: [''],
            sponsoredBy: ['', [Validators.required, Validators.maxLength(25)]],
            landingPageUrl: ['', [Validators.required, Validators.pattern(/^(https?:\/\/)((([a-zA-Z\d]([a-zA-Z\d-]*[a-zA-Z\d])*)\.)+[a-zA-Z]{2,}|\d{1,3}(\.\d{1,3}){3}(:\d+)?)(\/[-a-zA-Z\d%_.~+]*)*(\?[;&a-zA-Z\d%_.~+=-]*)?(#[-a-zA-Z\d_]*)?$/), Validators.maxLength(500)]],
            displayUrl: ['', [Validators.required, Validators.pattern(/^(https?:\/\/)((([a-zA-Z\d]([a-zA-Z\d-]*[a-zA-Z\d])*)\.)+[a-zA-Z]{2,}|\d{1,3}(\.\d{1,3}){3}(:\d+)?)(\/[-a-zA-Z\d%_.~+]*)*(\?[;&a-zA-Z\d%_.~+=-]*)?(#[-a-zA-Z\d_]*)?$/)]],
            videoUrl: ['', [Validators.required, Validators.pattern(/^(https?\:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/)]],
            id: [''],
            statusId: [],
            statusLabel: [''],
        });

        this.targetingKeywordFormGroup = this.fb.group({
            keyword: ['', Validators.required],
            match_type_label: ['', Validators.required],
            active: [false],
            id: [Validators.required],
            match_type_id: [Validators.required],
            type_id: [Validators.required],
            type_label: [Validators.required]
        });

        this.negativeKeywordFormGroup = this.fb.group({
            keyword: ['', Validators.required],
            match_type_label: ['', Validators.required],
            active: [false],
            id: [Validators.required],
            match_type_id: [Validators.required],
            type_id: [Validators.required],
            type_label: [Validators.required]
        });
    }

    get platformIdControl(): FormControl {
        return this.form.get('platformId') as FormControl;
    }

    get objectiveControl(): FormControl {
        return this.form.get('objectiveControl') as FormControl;
    }

    get campaignNameFormGroup(): FormGroup {
        return this.form.get('campaignNameForm') as FormGroup;
    }

    get creativeFormGroup(): FormGroup {
        return this.form.get('creativeFormGroup') as FormGroup;
    }

    get budgetFormGroup(): FormGroup {
        return this.form.get('budgetFormGroup') as FormGroup;
    }

    get targetingFormGroup(): FormGroup {
        return this.form.get('targetingFormGroup') as FormGroup;
    }

    get advertiserDialogGroup(): FormGroup {
        return this.form.get('advertiserDialogGroup') as FormGroup;
    }

    get programmaticProximityFormArray(): FormArray {
        return this.targetingFormGroup.get('programmaticProximityArray') as FormArray;
    }

    get proximityFormArray(): FormArray {
        return this.targetingFormGroup.get('proximityTargetsArray') as FormArray;
    }

    get locationsArray(): FormArray {
        return this.targetingFormGroup.get('locations') as FormArray;
    }

    get demographicCountriesArray(): FormArray {
        return this.targetingFormGroup.get('demographicCountries') as FormArray;
    }

    get ageGroupsArray(): FormArray {
        return this.targetingFormGroup.get('ageGroups') as FormArray;
    }

    get runningDaysArray(): FormArray {
        return this.budgetFormGroup.get('runningDays') as FormArray;
    }

    get genderGroupsArray(): FormArray {
        return this.targetingFormGroup.get('genderGroups') as FormArray;
    }

    get devicesGroupsArray(): FormArray {
        return this.targetingFormGroup.get('devices') as FormArray;
    }

    get environmentsArray(): FormArray {
        return this.targetingFormGroup.get('environmentsArray') as FormArray;
    }

    get interestsArray(): FormArray {
        return this.targetingFormGroup.get('interests') as FormArray;
    }

    get geofenceListArray(): FormArray {
        return this.targetingFormGroup.get('geofenceList') as FormArray;
    }

    get congressionalDistrictListArray(): FormArray {
        return this.targetingFormGroup.get('congressionalDistrict') as FormArray;
    }

    get whiteListedKeywordsArray(): FormArray {
        return this.targetingFormGroup.get('whiteListedKeywords') as FormArray;
    }

    get blackListedKeywordsArray(): FormArray {
        return this.targetingFormGroup.get('blackListedKeywords') as FormArray;
    }

    get whiteListedDomainsArray(): FormArray {
        return this.targetingFormGroup.get('whiteListedDomains') as FormArray;
    }

    get blackListedDomainsArray(): FormArray {
        return this.targetingFormGroup.get('blackListedDomains') as FormArray;
    }

    get languagesArray(): FormArray {
        return this.targetingFormGroup.get('languages') as FormArray;
    }

    get programmaticCreativesArray(): FormArray {
        return this.creativeFormGroup.get('programmaticCreativesArray') as FormArray;
    }

    get youtubeCreativesArray(): FormArray {
        return this.creativeFormGroup.get('youtubeCreativesArray') as FormArray;
    }

    get contactUsGroup(): FormGroup {
        return this.form.get('contactUsGroup') as FormGroup;
    }


    get filterFormGroup(): FormGroup {
        return this.form.get('filterFormGroup') as FormGroup;
    }

    get contractIdArray(): FormArray {
        return this.targetingFormGroup.get('selectedContract') as FormArray;
    }

    get postalCodeCountriesArray(): FormArray {
        return this.targetingFormGroup.get('postalCodeCountriesArray') as FormArray;
    }

    get verificationFormGroup(): FormGroup {
        return this.form.get('verificationFormGroup') as FormGroup;
    }

    get organisationVerificationFormArray(): FormArray {
        return this.verificationFormGroup.get('organisationVerificationFormArray') as FormArray;
    }

    // Search
    get searchBudgetGroup(): FormGroup {
        return this.searchForm.get('searchBudgetGroup') as FormGroup;
    }

    get keywordFormGroup(): FormGroup {
        return this.searchForm.get('keywordFormGroup') as FormGroup;
    }

    get targetingFormArray(): FormArray {
        return this.keywordFormGroup.get('targetingFormArray') as FormArray
    }

    get negativeFormArray(): FormArray {
        return this.keywordFormGroup.get('negativeFormArray') as FormArray
    }

    get adsFormGroup(): FormGroup {
        return this.searchForm.get('adsFormGroup') as FormGroup;
    }

    get searchHeadlinesFormArray(): FormArray {
        return this.adsFormGroup.get('additional_headlines') as FormArray;
    }

    get searchDescriptionsFormArray(): FormArray {
        return this.adsFormGroup.get('additional_descriptions') as FormArray;
    }

    get searchTargetingFormGroup(): FormGroup {
        return this.searchForm.get('targetingFormGroup') as FormGroup;
    }

    get searchPostalCodeCountriesArray(): FormArray {
        return this.searchTargetingFormGroup.get('postalCodeCountriesArray') as FormArray;
    }

    get searchDevicesGroupsArray(): FormArray {
        return this.searchTargetingFormGroup.get('devices') as FormArray;
    }

    get searchLanguagesArray(): FormArray {
        return this.searchTargetingFormGroup.get('languages') as FormArray;
    }

    get searchLocationsArray(): FormArray {
        return this.searchTargetingFormGroup.get('locations') as FormArray;
    }

    get searchProximityFormArray(): FormArray {
        return this.searchTargetingFormGroup.get('proximityTargetsArray') as FormArray;
    }

    get searchCampaignNameFormGroup(): FormGroup {
        return this.searchForm.get('campaignNameForm') as FormGroup;
    }

    get adsCreativeArray(): FormArray {
        return this.searchForm.get('adsCreativeArray') as FormArray;
    }

    // You can add more methods to manipulate the form if needed
    public resetForm(): void {
        this.form.reset();
    }

    cloneFormGroup(formGroup: FormGroup): FormGroup {
        let cloned = new FormGroup({});
        Object.keys(formGroup.controls).forEach(key => {
            cloned.addControl(key, new FormControl(formGroup.controls[key].value));
        });
        return cloned;
    }

    searchFormReset(): void {
        this.searchForm.reset();
        this.searchHeadlinesFormArray.clear();
        this.searchDescriptionsFormArray.clear();
        this.adsCreativeArray.clear();
        this.targetingFormArray.clear();
        this.negativeFormArray.clear();
        this.searchDevicesGroupsArray.clear();
        this.searchLanguagesArray.clear();
        this.searchLocationsArray.clear();
        this.searchProximityFormArray.clear();
        this.searchPostalCodeCountriesArray.clear();
    }
}