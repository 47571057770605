import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'advocate-ui-or-divider',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './or-divider.component.html',
  styleUrls: ['./or-divider.component.scss']
})
export class OrDividerComponent implements OnInit, OnDestroy {
  translatedText: string = '';
  private subscription: Subscription = new Subscription();

  constructor(private translateService: TranslateService) { }

  ngOnInit() {
    this.subscription = this.translateService.get('GENERAL.OR').subscribe(text => this.translatedText = text);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
